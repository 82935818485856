import './card.scss';
import classnames from 'classnames';
import React from 'react';
import { Box, Props as BoxProps } from './../box/box';

interface Props extends BoxProps {
  background?:
    | 'tertiary-base'
    | 'secondary-tint'
    | 'transparent'
    | 'secondary-base'
    | 'actioncard-primary-base'
    | 'actioncard-primary-inactive';
  className?: string;
  overflow?: 'hidden' | 'visible';
  outline?: 'primary-weakest' | 'text-tertiary-base' | 'text-error-subtle';
  width?: 'full' | 'auto';
}

export function Card({
  children,
  padding,
  tabletPadding,
  desktopPadding,
  background,
  className,
  overflow,
  outline,
  width,
}: Props): JSX.Element {
  return (
    <div
      className={classnames(
        'card',
        background && `card--background-${background}`,
        overflow && `card--overflow-${overflow}`,
        outline && `card--outline-${outline}`,
        width && `card--width-${width}`,
        className
      )}
    >
      <Box padding={padding} tabletPadding={tabletPadding} desktopPadding={desktopPadding}>
        {children}
      </Box>
    </div>
  );
}
