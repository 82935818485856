import './modal.scss';
import React, { useState, useContext, useMemo, createContext, ReactNode, useEffect } from 'react';
import classnames from 'classnames';
import { Stack } from '../stack/stack';
import { Icon } from '../icon/icon';

export interface Props {
  open?: boolean;
  title?: ReactNode;
  titleAlign?: 'top' | 'center' | 'bottom';
  updateKey?: string;
  children?: () => React.ReactNode;
  onClose?: () => void;
  onContentScroll?: (e: React.UIEvent<HTMLDivElement, UIEvent>) => void;
}

function ModalPrompt(props: Props): JSX.Element {
  const { modal, setModal } = useContext(ModalContext);
  const { title, children, onClose } = props;

  function handleOnClose(): void {
    onClose?.();
    setModal?.({ ...modal, open: false });
  }

  const [localOpen, setLocalOpen] = useState(false);
  const [displayChildren, setDisplayChildren] = useState(false);

  useEffect(() => {
    document.body.classList.toggle('with-modal', localOpen);
  }, [localOpen]);

  if (modal?.open && !localOpen) {
    setDisplayChildren(true);
    setLocalOpen(true);
  }
  if (!modal?.open && localOpen) {
    setLocalOpen(false);
    setTimeout(() => {
      setDisplayChildren(false);
    }, 300);
  }

  useEffect(() => {
    const closeOnKeyPress = (e: KeyboardEvent): void => {
      if (e.code === 'Escape') {
        handleOnClose();
      }
    };

    document.addEventListener('keydown', closeOnKeyPress);

    return () => {
      document.removeEventListener('keydown', closeOnKeyPress);
    };
  }, [modal]);

  return (
    <div
      className={classnames('modal', {
        'modal--open': localOpen,
      })}
    >
      <div className="modal__background" onClick={handleOnClose} />
      <div className="modal__content">
        <Stack size="s" tabletSize="xl">
          <header className="modal__header">
            <Stack line size="s" justify="space-between" align={props.titleAlign}>
              {title}
              {handleOnClose && (
                <div role="button" className="modal__close" onClick={handleOnClose}>
                  <Icon name="close" />
                </div>
              )}
            </Stack>
          </header>
          {displayChildren && (
            <section className="modal__body">
              <div className="modal__main" onScroll={props?.onContentScroll}>
                {children?.()}
              </div>
            </section>
          )}
        </Stack>
      </div>
    </div>
  );
}

export const ModalContext = createContext<{
  modal?: Props | null;
  setModal?: React.Dispatch<React.SetStateAction<Props | null>>;
}>({});

export function useModal(
  props: Props,
  key?: string
): {
  modal?: Props | null;
  isOpen: boolean;
  displayModal: (open: boolean) => void;
  updateModal: (modal?: Props | null, open?: boolean) => void;
} {
  const { modal, setModal } = useContext(ModalContext);

  useEffect(() => {
    setModal?.(props);
  }, [key]);

  return {
    modal,
    isOpen: modal?.open || false,
    displayModal: (open) => {
      setModal?.({ ...modal, open });
    },
    updateModal: (modal, open) => {
      setModal?.({ ...modal, open } || {});
    },
  };
}

export function ModalWrapper({ children }: { children: ReactNode }): JSX.Element {
  const [modal, setModal] = useState<Props | null>(null);

  const modalContextValue = useMemo(() => ({ modal, setModal }), [modal]);

  return (
    <ModalContext.Provider value={modalContextValue}>
      <ModalPrompt {...(modal || {})} />
      {children}
    </ModalContext.Provider>
  );
}
