export function smoothLine(data: number[]): number[] {
  const smoothedData: number[] = [];
  const numPoints = data.length;

  // this value was determined by looking at a chart with ~150 data points
  // with that size the `smoothingFactor` was 10, which produced a nice result -> 10 / 150 = ~0.066
  // now after we apply that ratio to sets of data with different length we will get proportional smoothing factor
  const smoothingRatio = 0.066;
  const smoothingFactor = numPoints * smoothingRatio;

  for (let i = 0; i < numPoints; i++) {
    const start = Math.max(0, i - Math.floor(smoothingFactor / 2));
    const end = Math.min(numPoints - 1, i + Math.ceil(smoothingFactor / 2));
    const sum = data.slice(start, end + 1).reduce((acc, val) => acc + val, 0);
    const average = sum / (end - start + 1);
    smoothedData.push(average);
  }

  return smoothedData;
}
