import './pie_chart.scss';
import React from 'react';
import { ResponsiveContainer, PieChart as RePieChart, Pie, Cell } from 'recharts';
import { Stack, Props as StackProps } from '../stack/stack';
import { ChartLabels } from './chart_labels';
import { ChartValue } from './chart';

interface Props {
  data: ChartValue[];
  showLabels?: boolean;
  stackProps?: StackProps;
}

export function PieChart({ data, showLabels, stackProps }: Props): JSX.Element {
  return (
    <Stack {...stackProps}>
      <div className="pie-chart">
        <ResponsiveContainer width="100%">
          <RePieChart>
            <defs>
              {data.map((entry, index) => {
                const gradient = typeof entry.color === 'string' ? [entry.color, entry.color] : entry.color;
                return (
                  <linearGradient key={entry.label} id={`myGradient${index}`} x1="0" y1="0" x2="1" y2="0">
                    <stop offset="0" stopColor={gradient?.[0]} />
                    <stop offset="1" stopColor={gradient?.[1]} />
                  </linearGradient>
                );
              })}
            </defs>
            <Pie
              stroke="none"
              data={data}
              cx="50%"
              cy="50%"
              innerRadius={40}
              outerRadius={65}
              fill="#8884d8"
              dataKey="value"
              nameKey="label"
              isAnimationActive={false}
              startAngle={-270}
            >
              {data.map((_, index) => (
                <Cell key={`cell-${index}`} fill={`url(#myGradient${index})`} />
              ))}
            </Pie>
          </RePieChart>
        </ResponsiveContainer>
      </div>
      {showLabels && <ChartLabels data={data} />}
    </Stack>
  );
}
