import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { Box } from '../components/box/box';
import { Button } from '../components/button/button';
import { Container } from '../components/container/container';
import { Icon } from '../components/icon/icon';
import { Stack } from '../components/stack/stack';
import { Text } from '../components/text/text';
import { i18n } from '../shared/i18n';
import LaicLogo from './../public/logos/laic_logo.svg';

export function NotFound(): JSX.Element {
  return (
    <Container>
      <Box padding={{ vertical: 's' }}>
        <Stack size="xxs" justify="center">
          <Link to="/">
            <img src={LaicLogo} alt="Laic Logo" className="navbar__logo" />
          </Link>
          <Box padding={{ vertical: 'mega' }}>
            <Stack size="xl" justify="center">
              <Text color="label-tertiary-base">
                <Icon name="404" size="s-mega" />
              </Text>
              <Stack size="l" justify="center">
                <Stack size="xxs" justify="center">
                  <Text type="h1" color="primary-base" align="center">
                    {i18n.t('pages.not_found.not_found')}
                  </Text>
                  <Text type="h2" color="label-primary-subtle" align="center">
                    {i18n.t('pages.not_found.page_might_be_renamed_removed')}
                  </Text>
                </Stack>
                <NavLink to="/">
                  <Button title={i18n.t('pages.not_found.back_to_beginning')} />
                </NavLink>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}
