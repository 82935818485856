import './text.scss';
import React from 'react';
import classnames from 'classnames';

export type Type =
  | 'huge'
  | 'h1'
  | 'h2'
  | 'h2-strong'
  | 'h2-black'
  | 'h3'
  | 'h3-bold'
  | 'h3-strong'
  | 'paragraph'
  | 'caption'
  | 'caption-strong'
  | 'tiny';

export type Color =
  | 'primary-base'
  | 'label-primary-base'
  | 'label-primary-subtle'
  | 'label-tertiary-base'
  | 'label-primary-tint'
  | 'text-action-base'
  | 'text-tertiary-base'
  | 'text-error-subtle'
  | 'text-link-primary-base'
  | 'actioncard-primary-base'
  | 'actioncard-primary-subtle';

interface Props {
  className?: string;
  children: React.ReactNode;
  type?: Type;
  color?: Color;
  uppercase?: boolean;
  align?: 'left' | 'center' | 'right';
}

export function Text({ children, className: propClassName, type, color, uppercase, align }: Props): JSX.Element {
  return (
    <div
      className={classnames(
        'text',
        `text--${type || 'paragraph'}`,
        color && `text--color-${color}`,
        align && `text--align-${align}`,
        propClassName,
        {
          'text--uppercase': uppercase,
        }
      )}
    >
      {children}
    </div>
  );
}
