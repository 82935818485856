import React from 'react';
import { Stack } from '../stack/stack';
import { useForm } from '@nerdgeschoss/react-use-form-library';
import { Text } from '../text/text';
import { Numberfield } from '../form/numberfield';
import { Box } from '../box/box';
import { Button } from '../button/button';
import { i18n } from '../../shared/i18n';

export type Range = {
  min: number;
  max: number;
  insufficientFunds?: boolean;
};

interface Props {
  transactionValue?: number;
  onSubmit: (transactionValue?: number) => void;
  text: {
    title: string;
    label: string;
    minimumAmount: string;
    confirm: string;
    rangeError: string;
  };
  range: Range;
}

export function TransactionInput({ transactionValue, onSubmit: handleOnSubmit, text, range }: Props): JSX.Element {
  const { fields, onSubmit, valid } = useForm<{ transactionValue?: number }>({
    model: {
      transactionValue,
    },
    validations: {
      transactionValue: (f) => {
        if (f.model.transactionValue === undefined) return ['required'];
        if (range?.insufficientFunds) return [i18n.t('pages.withdraw.insufficient_funds')];
        if (f.model.transactionValue < range.min || f.model.transactionValue > range.max) return [text.rangeError];
        return [];
      },
    },
    onSubmit: (form) => {
      handleOnSubmit(form.model.transactionValue);
    },
  });

  return (
    <Stack align="space-between">
      <Stack>
        <Text type="h1">{text.title.toUpperCase()}</Text>
        <Stack size="xs">
          <Text type="h3" color="label-primary-subtle">
            {text.label}
          </Text>
          <div>
            <Numberfield
              placeholder="0"
              adornment={
                <Box padding={{ top: 'xxxs' }}>
                  <Text type="tiny">€</Text>
                </Box>
              }
              {...fields.transactionValue}
              ariaLabel={text.label}
              format
            />
          </div>
        </Stack>
      </Stack>
      <Stack>
        <Text type="caption" color="label-primary-subtle">
          {text.minimumAmount}
        </Text>
        <Button onClick={() => onSubmit()} title={text.confirm} type="secondary" disabled={!valid} />
      </Stack>
    </Stack>
  );
}
