import { i18n } from './i18n';

export type timeframeId = 'twrSinceInception' | 'twrYtd' | 'twr1y';

export const timeframeOptions: Array<{
  id: timeframeId;
  label: string;
}> = [
  {
    id: 'twrSinceInception',
    label: i18n.t('pages.home.timeframe.since_inception'),
  },
  {
    id: 'twrYtd',
    label: i18n.t('pages.home.timeframe.ytd'),
  },
  {
    id: 'twr1y',
    label: i18n.t('pages.home.timeframe.1y'),
  },
];
