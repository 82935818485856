import './asset_dropdown.scss';
import React from 'react';
import { Icon } from '../icon/icon';
import { CollapsePanel } from '../collapse_panel/collapse_panel';
import { List } from '../list/list';
import { Stack } from '../stack/stack';
import classnames from 'classnames';
import { PortfolioBreakdown } from '../../generated/graphql';
import { formatPercentage } from '../../shared/format';
import { Pill } from '../pill/pill';
import { Text } from '../text/text';

interface Props {
  asset: PortfolioBreakdown;
}

export function AssetDropdown({ asset }: Props): JSX.Element {
  const [active, setActive] = React.useState(false);

  return (
    <div
      className={classnames('asset-dropdown', {
        'asset-dropdown--active': active,
      })}
    >
      <div
        className="asset-dropdown__border"
        style={{
          background: `linear-gradient(${asset.assetClass.chartColors[0]}, ${asset.assetClass.chartColors[1]})`,
        }}
      ></div>
      <div className="asset-dropdown__main">
        <header className="asset-dropdown__header" onClick={() => setActive((state) => !state)}>
          <Stack line justify="space-between">
            <Text type={active ? 'h3-bold' : 'h3'} color="primary-base">
              {asset.assetClass.name}
            </Text>
            <Stack line size="s" inline>
              <Pill>
                <Text type="caption-strong" color="primary-base">
                  {formatPercentage(asset.percentage)}
                </Text>
              </Pill>
              <div className="asset-dropdown__icon">
                <Icon name="chevron-right" size="s" />
              </div>
            </Stack>
          </Stack>
        </header>
        <CollapsePanel open={active}>
          <div className="asset-dropdown__content">
            <List
              theme="cards"
              items={asset.assets.nodes.map((subAsset) => {
                return {
                  id: subAsset.id,
                  content: (
                    <Stack line justify="space-between">
                      <Text type="h3" color="label-primary-subtle">
                        {subAsset.name}
                      </Text>
                      <Text type="h3" color="label-primary-subtle">
                        {formatPercentage(subAsset.percentage)}
                      </Text>
                    </Stack>
                  ),
                };
              })}
            />
          </div>
        </CollapsePanel>
      </div>
    </div>
  );
}
