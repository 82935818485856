import { Button } from '../button/button';
import React from 'react';
import { Stack } from '../stack/stack';
import { Text } from '../text/text';
import { i18n } from '../../shared/i18n';

export function TransactionWireInfo({
  clearingIban,
  onClose,
}: {
  clearingIban?: string | null;
  onClose?: () => void;
}): JSX.Element {
  return (
    <Stack size="mega">
      <Stack size="m">
        <Text type="h1">{i18n.t('transaction_wire_info.title')}</Text>
        <Text type="h2">{i18n.t('transaction_wire_info.description', { iban: clearingIban })}</Text>
      </Stack>
      <Stack size="s">
        <Text type="tiny">
          {i18n.t('transaction_wire_info.contact', {
            email: <a href="mailto:info@meinebv.de">info@meinebv.de</a>,
          })}
        </Text>
        <Button type="primary" title={i18n.t('transaction_wire_info.button')} onClick={onClose} />
      </Stack>
    </Stack>
  );
}
