import './error.scss';

import React, { useState } from 'react';

import { ApolloError } from '@apollo/client';
import { CollapsePanel } from '../collapse_panel/collapse_panel';
import { Stack } from '../stack/stack';
import { i18n } from '../../shared/i18n';

interface Props {
  error?: Error | ApolloError;
  justify?: 'left' | 'center' | 'right';
}

export function Error({ error, justify = 'left' }: Props): JSX.Element | null {
  const [open, setOpen] = useState(false);

  if (!error) return null;

  return (
    <div className="error">
      <Stack justify={justify}>
        <button className="error__button" onClick={() => setOpen((state) => !state)}>
          {i18n.t('errors.display_error')}
        </button>
      </Stack>
      <CollapsePanel open={open}>
        <pre className="error__name" style={{ whiteSpace: 'pre-wrap' }}>
          {error.name}:
        </pre>
        <pre className="error__message" style={{ whiteSpace: 'pre-wrap' }}>
          {error.message}
        </pre>
      </CollapsePanel>
    </div>
  );
}
