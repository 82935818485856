import { Icon, IconName } from '../components/icon/icon';

import { Box } from '../components/box/box';
import { Card } from '../components/card/card';
import { Container } from '../components/container/container';
import { Depot } from '../generated/graphql';
import { KeyValueTable } from '../components/key_value_table/key_value_table';
import { List } from '../components/list/list';
import { Logout } from '../components/logout/logout';
import { PriceDisplay } from '../components/price_display/price_display';
import React from 'react';
import { Stack } from '../components/stack/stack';
import { Text } from '../components/text/text';
import { Transact } from '../components/deposit_withdraw/transact';
import { formatIban } from '../shared/format';
import { graphql } from '../generated';
import { i18n } from '../shared/i18n';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfig } from '../shared/config';
import { useQuery } from '@apollo/client';
import { compact } from 'lodash';

const serviceOptionsTranslations = [
  'pages.service_options.set_up_savings_plan',
  'pages.service_options.change_investment_preference',
  'pages.service_options.adjust_strategy',
  'pages.service_options.adjust_personal_data',
  'pages.service_options.send_request',
  'pages.service_options.set_up_exemption_order',
  'pages.service_options.cancel_account',
];

const GET_PROFILE_DATA = graphql(`
  query GetProfileData {
    viewer {
      email
      givenName
      familyName
      name
    }
    depot {
      id
      clearingIban
      strategy
      depotNumber
      value
    }
  }
`);

export function Profile(): JSX.Element {
  const { logout } = useAuth0();
  const { loading, error, data } = useQuery(GET_PROFILE_DATA);
  const { transactionsEnabled, branding, privacyUrl, serviceEmail } = useConfig();

  const depot = data?.depot as Depot;

  const links = [
    {
      id: 'customer-service',
      icon: 'contact' as IconName,
      content: i18n.t('pages.profile.customer_service'),
      collapsibleContent: (
        <List
          hover={false}
          items={serviceOptionsTranslations.map((serviceOption) => ({
            id: `option-${serviceOption}`,
            content: (
              <Text type="h3" color="label-primary-subtle">
                {i18n.t(serviceOption)}
              </Text>
            ),
            actions: <Icon name="linkout" size="m" color="primary-base" />,
            href: `mailto:${serviceEmail}?subject=${i18n.t(serviceOption)}${
              serviceOption === 'pages.service_options.cancel_account' ? ` ${depot?.depotNumber}` : ''
            }`,
          }))}
        />
      ),
    },
    {
      id: 'privacy',
      icon: 'security' as IconName,
      content: i18n.t('pages.profile.privacy'),
      href: privacyUrl,
      actions: (
        <Text color="text-action-base">
          <Icon name="linkout" size="m" />
        </Text>
      ),
    },
  ];

  const entries = depot
    ? compact([
        {
          label: i18n.t('pages.profile.investment_profile'),
          value: depot.strategy,
        },
        {
          label: i18n.t('pages.profile.depot_number'),
          value: depot.depotNumber,
        },
        depot.clearingIban && {
          label: branding === 'laic' ? i18n.t('pages.profile.iban') : i18n.t('pages.profile.iban_mbv'),
          value: formatIban(depot.clearingIban),
        },
      ])
    : [];

  return (
    <Container title={i18n.t('pages.profile.profile')} loading={loading} error={error}>
      <Stack size="l" align="space-between">
        <Card padding="m" background="secondary-base">
          <Stack size="m">
            <Stack justify="center" size="xxxs">
              <Text color="label-primary-subtle">{i18n.t('pages.profile.balance')}</Text>
              <PriceDisplay value={data?.depot?.value} />
            </Stack>
            {transactionsEnabled && (
              <Stack line size="s" justify="center">
                <Transact buttonType="outline" portfolioValue={data?.depot?.value} />
              </Stack>
            )}
          </Stack>
        </Card>
        <Stack size="s" tabletSize="l">
          <Stack size="s" tabletSize="m">
            <Text type="h2-strong" color="label-primary-subtle" uppercase>
              {i18n.t('pages.profile.base_data')}
            </Text>
            <Card
              padding="m"
              tabletPadding={{
                horizontal: 'xl',
                vertical: 'l',
              }}
            >
              <Stack size="s" tabletSize="m">
                <Text type="h3-bold">{i18n.t('pages.profile.investment_information')}</Text>
                <KeyValueTable data={entries} />
              </Stack>
            </Card>
          </Stack>
          <Stack size="zero">
            <Text type="h2-strong" color="label-primary-subtle" uppercase>
              {i18n.t('pages.profile.other')}
            </Text>
            <List hover={false} fullWidth items={links} />
          </Stack>
        </Stack>
        <Box hidden="tablet">
          <Logout
            name={data?.viewer?.name}
            secondary
            onLogout={() => logout({ logoutParams: { returnTo: window.location.origin } })}
          />
        </Box>
      </Stack>
    </Container>
  );
}
