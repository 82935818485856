import './transaction.scss';
import classnames from 'classnames';
import React from 'react';
import { TransactionTransaction } from '../../generated/graphql';
import { formatDate } from '../../shared/format';
import { i18n } from '../../shared/i18n';
import { Text } from '../text/text';
import { PriceDisplay } from '../price_display/price_display';

interface Props {
  transaction: {
    id: string;
    amount: number;
    description: string;
    happenedOn: Date;
    transactionType: TransactionTransaction;
    pending?: boolean;
  };
  hideYear?: boolean;
  hideDescription?: boolean;
  modalLayout?: boolean;
  coloredDisplay?: boolean;
}

export function TransactionCell({
  transaction,
  hideYear,
  hideDescription,
  coloredDisplay,
  modalLayout,
}: Props): JSX.Element | null {
  if (!transaction) return null;

  return (
    <div
      className={classnames('transaction', {
        'transaction--pending': transaction.pending,
        'transaction--modal': modalLayout,
        [`transaction--colored-${transaction.transactionType}`]: coloredDisplay,
      })}
    >
      <div className={classnames('transaction__date', { 'transaction__date--modal': modalLayout })}>
        <Text type="caption" color="label-primary-subtle">
          {formatDate(transaction.happenedOn, { year: hideYear ? undefined : '2-digit' })}
        </Text>
      </div>
      <div className={classnames('transaction__type', { 'transaction__type--modal': modalLayout })}>
        <Text type="caption" color="label-primary-subtle">
          {i18n.t(`transaction.${transaction.transactionType}`)}
        </Text>
      </div>
      {!hideDescription && (
        <div className="transaction__description">
          <Text type="h3">{transaction.description}</Text>
        </div>
      )}
      <div className={classnames('transaction__amount', { 'transaction__amount--modal': modalLayout })}>
        <PriceDisplay value={transaction.amount} type="tertiary" />
      </div>
    </div>
  );
}
