import './loader.scss';
import React from 'react';

export function Loader(): JSX.Element {
  return (
    <ul className="loader">
      <li className="loader__item"></li>
      <li className="loader__item"></li>
      <li className="loader__item"></li>
    </ul>
  );
}
