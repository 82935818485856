import React from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ModalWrapper } from './components/modal/modal';
import { NotFound } from './pages/not_found';
import { Portfolio } from './pages/portfolio';
import { Postbox } from './pages/postbox';
import { Profile } from './pages/profile';
import { timeSensitiveGreeting } from './shared/date';
import { Transactions } from './pages/transactions';
import { useAutoLogout } from './shared/session';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './components/error_fallback/error_fallback';
import { MainLayout } from './layout/main';
import { Home } from './pages/home';
import { useAuth0 } from '@auth0/auth0-react';
import { Auth } from './pages/auth';
import { testToken } from './index';

export function App(): JSX.Element {
  const { isAuthenticated, isLoading, user, logout, error } = useAuth0();
  const userHadActivity = useAutoLogout({
    logout: () => logout({ logoutParams: { returnTo: window.location.origin } }),
  });
  const path = useLocation().pathname;
  const navigate = useNavigate();

  if (isLoading) return <div />;
  if (!isAuthenticated && !error && !testToken) return <Auth />;
  if (error) return <div>{error.message}</div>;
  if (path === '/auth') {
    navigate('/');
    return <div />;
  }

  const greetingName = user?.name;
  const greetingTime = timeSensitiveGreeting();

  const mainLayoutProps = { userHadActivity, greetingName, greetingTime };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        window.location.reload();
      }}
    >
      <ModalWrapper>
        <Routes>
          <Route path="/" element={<MainLayout {...mainLayoutProps} />}>
            <Route index element={<Home />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/postbox" element={<Postbox />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/auth" element={null} />
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </ModalWrapper>
    </ErrorBoundary>
  );
}
