import './postbox_document.scss';
import classnames from 'classnames';
import React from 'react';
import { Document } from '../../generated/graphql';
import { Stack } from '../stack/stack';
import { Text } from '../text/text';
import { formatRelativeDate } from '../../shared/format';

interface Props {
  item?: Document;
  onOpen?: () => void;
}

export function PostboxDocument({ item, onOpen }: Props): JSX.Element | null {
  if (!item) return null;

  return (
    <a className="postbox-document" href={item.url} target="_blank" rel="noreferrer" onClick={onOpen}>
      <Stack size="xxs" line align="top">
        <Stack size="xxxs">
          <Text type="tiny" color="label-primary-subtle">
            {item.author}
          </Text>
          <p className={classnames('postbox-document__title', { 'postbox-document__title--unread': !item.read })}>
            {item.name}
          </p>
          <Text type="tiny" color="label-primary-subtle">
            {formatRelativeDate(item.createdAt)}
          </Text>
        </Stack>
      </Stack>
    </a>
  );
}
