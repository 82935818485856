import { ApolloClient, InMemoryCache, Reference, StoreObject, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';

export type GraphClient = ApolloClient<unknown>;

export function createClient(uri: string, authToken: () => Promise<string>): GraphClient {
  const httpLink = createHttpLink({
    uri,
  });

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = await authToken();

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache({
      typePolicies: {
        Depot: {
          fields: {
            transactions: relayStylePagination(),
          },
        },
      },
    }),
    defaultOptions: {
      watchQuery: {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
      },
    },
  });
}

export function updateCache<T extends StoreObject | Reference>(
  client: GraphClient,
  element: T,
  changes: Partial<T>
): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const fields: any = {};
  Object.keys(changes).forEach((key) => (fields[key as keyof T] = () => changes[key as keyof T]));
  client.cache.modify({ id: client.cache.identify(element), fields });
}
