import React from 'react';
import { Box } from '../box/box';
import { Button } from '../button/button';
import { Numberfield } from '../form/numberfield';
import { Stack } from '../stack/stack';
import { Text } from '../text/text';

interface Props {
  transactionValue?: number;
  loading?: boolean;
  onConfirm: () => void;
  text: {
    title: string;
    label: string;
    disclaimer: string;
    review: string;
    confirm: string;
  };
}

export function TransactionConfirm({ transactionValue, loading, onConfirm, text }: Props): JSX.Element {
  return (
    <Stack align="space-between">
      <Stack>
        <Text type="h1">{text.title.toUpperCase()}</Text>
        <Stack size="s">
          <Text type="h3" color="label-primary-subtle">
            {text.label}
          </Text>
          <Numberfield
            readOnly
            value={transactionValue}
            adornment={
              <Box padding={{ top: 'xxxs' }}>
                <Text type="tiny">€</Text>
              </Box>
            }
            format
          />
          <Text type="caption" color="label-primary-subtle">
            {text.disclaimer}
          </Text>
        </Stack>
      </Stack>
      <Stack>
        <Text type="caption" color="label-primary-tint">
          {text.review}
        </Text>
        <Button loading={loading} onClick={onConfirm} type="secondary" title={text.confirm} />
      </Stack>
    </Stack>
  );
}
