import { Color, Text, Type as TextType } from '../text/text';

import { Box } from '../box/box';
import React from 'react';
import { Stack } from '../stack/stack';
import classnames from 'classnames';
import { formatNumber } from '../../shared/format';

interface Props {
  value?: number | null;
  type?: 'primary' | 'secondary' | 'tertiary';
  color?: Color;
}

const styles = {
  primary: {
    valueType: 'huge',
    currencyType: 'h3',
  },
  secondary: {
    valueType: 'h2-black',
    currencyType: 'tiny',
  },
  tertiary: {
    valueType: 'h3-bold',
    currencyType: 'h3',
  },
};

export function PriceDisplay({ value = 0, type = 'primary', color }: Props): JSX.Element {
  const currency = (
    <Text color={color} type={styles[type].currencyType as TextType}>
      €
    </Text>
  );

  return (
    <div
      className={classnames('price-display', {
        'price-display--secondary': type === 'secondary',
      })}
    >
      <Stack line size="xxxs" align="top">
        <Text color={color} type={styles[type].valueType as TextType}>
          {formatNumber(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        </Text>
        {type === 'primary' ? <Box padding={{ top: 'xxs' }}>{currency}</Box> : currency}
      </Stack>
    </div>
  );
}
