import './icon.scss';
import classnames from 'classnames';
import React from 'react';

export type IconName =
  | 'menu'
  | 'overview'
  | 'transactions'
  | 'portfolio'
  | 'postbox'
  | 'profile'
  | 'sun'
  | 'slant-arrow'
  | 'plus'
  | 'close'
  | 'chevron-left'
  | 'chevron-right'
  | 'mail'
  | 'security'
  | 'error'
  | 'contact'
  | 'trend'
  | 'circle-arrow-left'
  | 'circle-arrow-right'
  | 'arrow-down'
  | 'exclamation-circle'
  | '404'
  | 'arrow-up'
  | 'linkout'
  | 'info'
  | 'transaction';

interface Props {
  color?: 'primary-base' | 'info-primary-base' | 'tertiary-base';
  size?: 's' | 'm' | 'l' | 'xl' | 'xxl' | 's-mega';
  className?: string;
  name: IconName;
}

export function Icon({ color, name, size = 'm', className }: Props): JSX.Element {
  return (
    <span
      className={classnames('icon', `icon--${name}`, `icon--${size}`, color && `icon--color-${color}`, className)}
    />
  );
}
