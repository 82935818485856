import { createContext, useContext } from 'react';

const supportedBrandings = ['laic', 'mbv'] as const;
export type Branding = typeof supportedBrandings[number];

interface Config {
  branding: Branding;
  auth0: {
    clientId: string;
    domain: string;
    audience: string;
  };
  transactionsEnabled: boolean;
  appDialogEnabled: boolean;
  title: string;
  privacyUrl: string;
  serviceEmail: string;
}

const configs: Record<Branding, Config> = {
  laic: {
    branding: 'laic',
    auth0: {
      clientId: 'A4arnl6c90Ex3KBxjy04m5z16Qjx8Bdd',
      domain: 'https://login.laic.de',
      audience: 'https://laic.de',
    },
    transactionsEnabled: true,
    appDialogEnabled: true,
    title: 'LAIC',
    privacyUrl: 'https://laic.de/datenschutz',
    serviceEmail: 'service@laic.de',
  },
  mbv: {
    branding: 'mbv',
    auth0: {
      clientId: 'uHxAnQgcUXaADkL7IJzg4qVuuVMuq7wy',
      domain: 'https://login.meinebv.de',
      audience: 'https://meinebv.de',
    },
    transactionsEnabled: false,
    appDialogEnabled: false,
    title: 'meine Bayerische Vermögen',
    privacyUrl: 'https://www.meinebv.de/de/datenschutz',
    serviceEmail: 'service@meinebv.de',
  },
};

export async function getConfig(branding: string | null): Promise<Config> {
  if (!branding) return configs.laic;
  return configs[branding as Branding] ?? configs.laic;
}

const context = createContext<Config | undefined>(undefined);
export const ConfigProvider = context.Provider;
export function useConfig(): Config {
  const config = useContext(context);
  if (!config) throw new Error('Config not available');
  return config;
}
