import { MonthlySummary, TransactionTransaction } from '../generated/graphql';
import { Props as ModalProps, useModal } from '../components/modal/modal';
import React, { useEffect, useState } from 'react';
import { formatCurrency, formatDate, formatMonth } from '../shared/format';

import { Box } from '../components/box/box';
import { Button } from '../components/button/button';
import { Card } from '../components/card/card';
import { Container } from '../components/container/container';
import { Icon } from '../components/icon/icon';
import { List } from '../components/list/list';
import { MonthlyTransactions } from './monthly_transactions';
import { Slider } from '../components/slider/slider';
import { SliderCard } from '../components/slider/slider_card';
import { Stack } from '../components/stack/stack';
import { StackedBarChart } from '../components/charts/stacked_bar_chart';
import { Text } from '../components/text/text';
import { TransactionCell } from '../components/transaction/transaction_cell';
import { graphql } from '../generated';
import { i18n } from '../shared/i18n';
import { useQuery } from '@apollo/client';

const GET_DATA = graphql(`
  query GetTransactions {
    depot {
      id
      monthlySummaries {
        nodes {
          id
          amount
          month
          transactions {
            nodes {
              id
            }
          }
          breakdowns {
            nodes {
              id
              percentage
              assetClass {
                id
                name
                chartColors
              }
            }
          }
        }
      }
      pendingTransactions {
        nodes {
          id
          createdAt
          value
          transactionType
        }
      }
      transactions(first: 10) {
        nodes {
          id
          happenedOn
          transactionType
          description
          amount
        }
      }
    }
  }
`);

export function Transactions(): JSX.Element {
  const { loading, error, data } = useQuery(GET_DATA);

  const [currentIndex, setCurrentIndex] = useState(0);
  const summaries = data?.depot?.monthlySummaries?.nodes as MonthlySummary[];
  const selectedMonthSummary = summaries?.[currentIndex];
  let transactions =
    data?.depot?.transactions.nodes.map((node) => ({
      id: node.id,
      content: <TransactionCell transaction={node} />,
    })) ?? [];
  const pendingTransactions = data?.depot?.pendingTransactions.nodes ?? [];
  if (pendingTransactions.length > 0) {
    transactions = pendingTransactions
      .map((node) => ({
        id: node.id,
        content: (
          <TransactionCell
            transaction={{
              id: node.id,
              amount: node.value,
              happenedOn: node.createdAt,
              transactionType:
                node.transactionType === 'deposit' ? TransactionTransaction.Deposit : TransactionTransaction.Withdrawal,
              description: i18n.t(
                node.transactionType === 'deposit'
                  ? 'pages.transactions.deposit_from_reference_account'
                  : 'pages.transactions.withdrawal_from_reference_account'
              ),
              pending: true,
            }}
          />
        ),
      }))
      .concat(transactions);
  }

  const chartData = selectedMonthSummary?.breakdowns?.nodes?.length
    ? selectedMonthSummary.breakdowns.nodes.map((node) => ({
        id: node.id,
        label: node.assetClass.name,
        value: node.percentage,
        color: node.assetClass.chartColors,
      }))
    : null;

  const modal: ModalProps = {
    title: selectedMonthSummary && <ModalHeader summary={selectedMonthSummary} />,
    titleAlign: 'top',
    children: () => selectedMonthSummary && <MonthlyTransactions id={selectedMonthSummary.id} />,
  };

  const { displayModal, updateModal } = useModal({ ...modal });

  useEffect(() => {
    updateModal(modal);
  }, [selectedMonthSummary]);

  return (
    <Container title={i18n.t('pages.transactions.transactions')} loading={loading} error={error}>
      <Card padding={{ top: 's', bottom: 'm' }} tabletPadding={{ vertical: 'l' }}>
        <Stack tabletSize="xl">
          <Box padding={{ horizontal: 'm' }} tabletPadding={{ horizontal: 'l' }} desktopPadding={{ horizontal: 'xl' }}>
            <Stack>
              <Text type="h2-strong" uppercase>
                {i18n.t('pages.transactions.monthly_summary')}
              </Text>
              {chartData && <StackedBarChart data={chartData} />}
            </Stack>
          </Box>
          <div className="summaries-slider">
            <Slider current={currentIndex} onChange={(index) => setCurrentIndex(index)} controls>
              {summaries?.map((summary) => {
                const active = summary.id === selectedMonthSummary?.id;
                return (
                  <SliderCard
                    key={summary.id}
                    active={active}
                    header={
                      <Text type="h3-bold" color={active ? 'actioncard-primary-base' : 'label-primary-base'}>
                        {formatDate(summary.month, {
                          month: 'long',
                          day: undefined,
                        })}
                      </Text>
                    }
                    footer={
                      <Button
                        type="link-secondary"
                        title={i18n.t('pages.transactions.summary_details')}
                        onClick={() => displayModal(true)}
                        disabled={summary.id !== selectedMonthSummary?.id}
                      />
                    }
                  >
                    <Text type="caption" color={active ? 'actioncard-primary-subtle' : 'label-primary-subtle'}>
                      {i18n.t('pages.transactions.transaction_count', {
                        count: summary.transactions.nodes?.length,
                      })}
                    </Text>
                    <Stack line size="xs">
                      <Icon name="trend" size="s" color="tertiary-base" />
                      <Text type="h3-bold" color={active ? 'actioncard-primary-base' : 'label-primary-base'}>
                        {formatCurrency(summary.amount)}
                      </Text>
                    </Stack>
                  </SliderCard>
                );
              })}
            </Slider>
          </div>
        </Stack>
      </Card>
      <Card
        padding={{ top: 's', bottom: 'm', horizontal: 'm' }}
        tabletPadding="l"
        desktopPadding={{
          vertical: 'l',
          horizontal: 'xl',
        }}
      >
        <Stack size="xl">
          <Stack size="xxs" tabletLine justify="space-between">
            <Text type="h2-strong" uppercase>
              {i18n.t('pages.transactions.recent_transactions')}
            </Text>
            <Text type="tiny" color="label-primary-subtle">
              {i18n.t('pages.transactions.transaction_count', { count: transactions?.length || 0 })}
            </Text>
          </Stack>
          <List theme="cards" items={transactions} />
        </Stack>
      </Card>
    </Container>
  );
}

function ModalHeader({ summary }: { summary: MonthlySummary }): JSX.Element {
  return (
    <Stack size="s">
      <Stack size="xxxs">
        <Text type="h3-bold" color="label-primary-subtle">
          {formatMonth(summary.month)}
        </Text>
        <Text type="caption" color="label-primary-subtle">
          {i18n.t('pages.transactions.transaction_count', { count: summary.transactions.nodes?.length })}
        </Text>
      </Stack>
      <Stack line size="xs">
        <Text color="text-tertiary-base">
          <Icon name="trend" size="s" />
        </Text>
        <Text type="h3-bold">{formatCurrency(summary.amount)}</Text>
      </Stack>
    </Stack>
  );
}
