import { useApolloClient } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { useConfig } from '../../shared/config';
import { requestDeposit } from '../../shared/depot';
import { formatCurrency } from '../../shared/format';
import { i18n } from '../../shared/i18n';
import { requestWithdrawal } from '../../shared/withdraw';
import { Button, ButtonType } from '../button/button';
import { useModal } from '../modal/modal';
import { Stack } from '../stack/stack';
import { TransactionComplete } from './transaction_complete';
import { TransactionConfirm } from './transaction_confirm';
import { Range, TransactionInput } from './transaction_input';
import { TransactionWireInfo } from './transaction_wire_info';

interface Props {
  buttonType?: ButtonType;
  displayTransaction?: 'deposit' | 'withdraw' | 'all';
  portfolioValue?: number;
  clearingIban?: string | null;
}

export function Transact({
  buttonType = 'primary',
  displayTransaction = 'all',
  portfolioValue,
  clearingIban,
}: Props): JSX.Element {
  const { transactionsEnabled } = useConfig();
  const [transactionType, setTransactionType] = useState<'deposit' | 'withdraw'>();
  const [transactionValue, setTransactionValue] = useState<number>();
  const [currentStep, setCurrentStep] = useState<1 | 2 | 3>(1);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const enableNavigation = useRef(false);
  const open = useRef(false);
  const transactId = useRef(crypto.randomUUID());
  const transactionRange: { withdraw: Range; deposit: Range } = {
    withdraw: {
      min: 500,
      max: portfolioValue ? portfolioValue - 5000 : 5000,
      insufficientFunds: portfolioValue ? portfolioValue - 5000 < 500 : false,
    },
    deposit: {
      min: 100,
      max: 10000,
    },
  };
  const translations = {
    transactionInput: {
      title: i18n.t(`pages.${transactionType}.name`),
      label: i18n.t(`pages.${transactionType}.how_much`),
      minimumAmount: i18n.t(`pages.${transactionType}.minimum`, {
        amount: transactionType && formatCurrency(transactionRange[transactionType].min),
      }),
      confirm: i18n.t(
        transactionType === 'withdraw' ? 'pages.withdraw.confirm_amount' : 'pages.deposit.confirm_amount'
      ),
      rangeError: i18n.t(`pages.${transactionType}.range_error`, {
        min: transactionType && formatCurrency(transactionRange[transactionType].min),
        max: transactionType && formatCurrency(transactionRange[transactionType].max),
      }),
    },
    transactionConfirm: {
      title: i18n.t(`pages.${transactionType}.confirm_title`),
      label: i18n.t(`pages.${transactionType}.about_to_operate`),
      disclaimer: i18n.t(
        transactionType === 'withdraw'
          ? 'pages.withdraw.funds_will_be_transferred'
          : 'pages.deposit.this_amount_will_take_time'
      ),
      review: i18n.t(`pages.${transactionType}.review`),
      confirm: i18n.t(transactionType === 'withdraw' ? 'pages.withdraw.confirm' : 'pages.deposit.confirm'),
    },
    transactionComplete: {
      title: i18n.t(`pages.${transactionType}.is_processing`),
      processing: i18n.t(`pages.${transactionType}.we_are_processing`, {
        amount: formatCurrency(transactionValue),
      }),
      done: i18n.t(`pages.${transactionType}.done`),
    },
  };

  async function handleOnConfirm(): Promise<void> {
    if (!transactionValue) return;
    setLoading(true);
    try {
      const variables = { id: transactId.current, value: transactionValue };
      if (transactionType === 'deposit') {
        await requestDeposit(client, variables);
      } else {
        await requestWithdrawal(client, variables);
      }
      setCurrentStep(3);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  }

  function handleOnClose(): void {
    enableNavigation.current = false;
    setTransactionValue(undefined);
    setTransactionType(undefined);
    setCurrentStep(1);
    open.current = false;
    transactId.current = crypto.randomUUID();
  }

  const transactionModal = {
    title:
      currentStep === 2 ? (
        <Button
          icon="chevron-left"
          iconLeft
          title={i18n.t('components.modal.back')}
          onClick={() => setCurrentStep(1)}
          type="link"
        />
      ) : undefined,
    children: () => {
      if (!transactionsEnabled) {
        return <TransactionWireInfo clearingIban={clearingIban} onClose={handleOnClose} />;
      }
      return transactionType ? (
        <>
          {currentStep === 1 && (
            <TransactionInput
              transactionValue={transactionValue}
              onSubmit={(value) => handleTransactionValue(value)}
              text={translations.transactionInput}
              range={transactionRange[transactionType]}
            />
          )}
          {currentStep === 2 && (
            <TransactionConfirm
              transactionValue={transactionValue}
              onConfirm={handleOnConfirm}
              loading={loading}
              text={translations.transactionConfirm}
            />
          )}
          {currentStep === 3 && <TransactionComplete onClose={handleOnClose} text={translations.transactionComplete} />}
        </>
      ) : null;
    },
    onClose: () => {
      handleOnClose();
    },
    open: open.current,
  };

  const { displayModal } = useModal(transactionModal, `${transactionType ? transactionType + currentStep : ''}`);

  function handleTransactionValue(value?: number): void {
    setTransactionValue(value);
    setCurrentStep(2);
  }

  function handleClick(transactionType: 'deposit' | 'withdraw'): void {
    setTransactionType(transactionType);
    enableNavigation.current = true;
    open.current = true;
    displayModal(true);
  }

  return (
    <Stack line size="s" justify={displayTransaction === 'all' ? 'center' : undefined}>
      {displayTransaction !== 'withdraw' && (
        <Button
          onClick={() => handleClick('deposit')}
          title={displayTransaction === 'all' ? i18n.t('pages.deposit.name') : i18n.t('pages.home.deposit')}
          icon={displayTransaction !== 'all' ? 'plus' : undefined}
          iconLeft
          type={buttonType}
        />
      )}
      {displayTransaction !== 'deposit' && (
        <Button
          onClick={() => handleClick('withdraw')}
          title={i18n.t('pages.withdraw.name')}
          icon={displayTransaction !== 'all' ? 'plus' : undefined}
          iconLeft
          type={buttonType}
        />
      )}
    </Stack>
  );
}
