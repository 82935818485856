import './error_fallback.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { Box } from '../box/box';
import { Button } from '../button/button';
import { Container } from '../container/container';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';
import { Text } from '../text/text';
import LaicLogo from './../../public/logos/laic_logo.svg';
import { Error } from '../error/error';
import { i18n } from '../../shared/i18n';

export function ErrorFallback({
  error,
  resetErrorBoundary,
}: {
  error: Error;
  resetErrorBoundary: () => void;
}): JSX.Element {
  return (
    <Container>
      <Box padding={{ vertical: 's' }}>
        <Stack size="xxs" justify="center">
          <Link to="/">
            <img src={LaicLogo} alt="Laic Logo" className="navbar__logo" />
          </Link>
          <Box padding={{ vertical: 'mega' }}>
            <Stack size="xl" justify="center">
              <Text color="label-tertiary-base">
                <Icon name="exclamation-circle" size="s-mega" />
              </Text>
              <Stack size="l" justify="center">
                <Stack size="xxs" justify="center">
                  <Text type="h1" color="primary-base" align="center">
                    {i18n.t('errors.something_went_wrong')}
                  </Text>
                  <Text type="h2" color="label-primary-subtle" align="center">
                    {i18n.t('errors.there_was_an_error')}
                  </Text>
                  <Error error={error} justify="center" />
                </Stack>
                <Button title={i18n.t('errors.display_error')} onClick={resetErrorBoundary} />
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Box>
    </Container>
  );
}
