import './hiddable.scss';
import React from 'react';
import classnames from 'classnames';

interface Props {
  children?: React.ReactNode;
  hidden?: boolean;
}

export function Hiddable({ children, hidden }: Props): JSX.Element {
  return (
    <div
      className={classnames('hiddable', {
        'hiddable--hidden': hidden,
      })}
    >
      {children}
    </div>
  );
}
