import './chart_labels.scss';
import React from 'react';
import { Text } from '../text/text';
import { formatPercentage } from '../../shared/format';
import { ChartValue } from './chart';
import classNames from 'classnames';

interface Props {
  data: ChartValue[];
  secondary?: boolean;
}

function getBackground(color?: string | string[], secondary?: boolean): string | undefined {
  if (typeof color === 'string') {
    return color;
  }
  if (secondary) {
    return color?.[0];
  }
  return `linear-gradient(${color?.[0]}, ${color?.[1]})`;
}

export function ChartLabels({ data, secondary }: Props): JSX.Element {
  return (
    <div className="chart-labels__container">
      <ul
        className={classNames('chart-labels', {
          'chart-labels--secondary': secondary,
        })}
      >
        {data.map((item) => {
          return (
            <li key={item.label} className="chart-labels__item">
              <div
                className="chart-labels__color"
                style={{
                  background: getBackground(item.color, secondary),
                }}
              />
              <Text className="chart-labels__label" type="h3" color="label-primary-subtle">
                {item.label}
              </Text>
              <Text className="chart-labels__value" type="h3-strong">
                {formatPercentage(Number(item.value))}
              </Text>
            </li>
          );
        })}
      </ul>
    </div>
  );
}
