import './button_bar.scss';
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Box } from '../box/box';

interface Props {
  activeId?: string;
  items: Array<{
    id?: string;
    label?: string;
    onClick?: () => void;
  }>;
}

export function ButtonBar({ activeId, items }: Props): JSX.Element {
  const listRef = useRef<HTMLUListElement>(null);
  const [minWidth, setMinWidth] = useState(0);

  useEffect(() => {
    if (listRef.current) {
      let min = 0;

      listRef.current.childNodes.forEach((node) => {
        const width = (node as HTMLLIElement).clientWidth;

        if (width > min) {
          min = width;
        }
      });

      setMinWidth(min);
    }
  }, []);

  return (
    <ul className="button-bar" ref={listRef}>
      {items.map((item) => {
        return (
          <li
            className={classnames('button-bar__item', { 'button-bar__item--active': item.id === activeId })}
            key={item.id}
            onClick={item.onClick}
            style={{ minWidth }}
          >
            <Box
              padding={{
                vertical: 'xs',
              }}
              tabletPadding={{
                horizontal: 'l',
                vertical: 'xs',
              }}
            >
              <div className="button-bar__label">{item.label}</div>
            </Box>
          </li>
        );
      })}
    </ul>
  );
}
