import React from 'react';
import { Card } from '../card/card';
import { Stack } from '../stack/stack';

interface Props {
  header?: React.ReactNode;
  children?: React.ReactNode;
  footer?: React.ReactNode;
  active?: boolean;
}

export function SliderCard({ header, children, footer, active }: Props): JSX.Element {
  return (
    <Card
      className="slider__card"
      padding={{ vertical: 's', horizontal: 'm' }}
      background={active ? 'actioncard-primary-base' : 'actioncard-primary-inactive'}
    >
      <Stack size="xxs">
        <header>{header}</header>
        {children}
        <footer>{footer}</footer>
      </Stack>
    </Card>
  );
}
