import './success.scss';
import React from 'react';

export function Success(): JSX.Element {
  return (
    <svg width="53" height="59" viewBox="0 0 53 59" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle className="success__circle" cx="26.8755" cy="33.7175" r="17" fill="#05F383" />
      <path
        d="M23.9696 37.6251L20.2168 33.8723C19.795 33.4505 19.1136 33.4505 18.6918 33.8723C18.27 34.2941 18.27 34.9754 18.6918 35.3972L23.2125 39.9179C23.6343 40.3397 24.3157 40.3397 24.7375 39.9179L36.1799 28.4755C36.6017 28.0538 36.6017 27.3724 36.1799 26.9506C35.7581 26.5288 35.0767 26.5288 34.6549 26.9506L23.9696 37.6251Z"
        fill="#002C77"
        className="success__check"
      />
      <path
        className="success__arrow"
        d="M52.8755 32.7175C52.8755 32.1652 52.4278 31.7175 51.8755 31.7175C51.3232 31.7175 50.8755 32.1652 50.8755 32.7175H52.8755ZM30.5826 8.42464C30.9731 8.03411 30.9731 7.40095 30.5826 7.01042L24.2186 0.646461C23.8281 0.255937 23.1949 0.255937 22.8044 0.646461C22.4139 1.03699 22.4139 1.67015 22.8044 2.06068L28.4613 7.71753L22.8044 13.3744C22.4139 13.7649 22.4139 14.3981 22.8044 14.7886C23.1949 15.1791 23.8281 15.1791 24.2186 14.7886L30.5826 8.42464ZM50.8755 32.7175C50.8755 45.9724 40.1303 56.7175 26.8755 56.7175V58.7175C41.2349 58.7175 52.8755 47.0769 52.8755 32.7175H50.8755ZM26.8755 56.7175C13.6207 56.7175 2.87549 45.9724 2.87549 32.7175H0.875488C0.875488 47.0769 12.5161 58.7175 26.8755 58.7175V56.7175ZM2.87549 32.7175C2.87549 19.4627 13.6207 8.71753 26.8755 8.71753V6.71753C12.5161 6.71753 0.875488 18.3581 0.875488 32.7175H2.87549ZM26.8755 8.71753H29.8755V6.71753H26.8755V8.71753Z"
        fill="#05F383"
      />
    </svg>
  );
}
