import { Card } from '../card/card';
import { Icon } from '../icon/icon';
import { PriceDisplay } from '../price_display/price_display';
import React from 'react';
import { Stack } from '../stack/stack';
import { Text } from '../text/text';
import { i18n } from '../../shared/i18n';

interface Props {
  type: 'deposit' | 'withdrawal';
  value: number;
}

export function NetInvestment({ type, value }: Props): JSX.Element {
  const isDeposit = type === 'deposit';
  const color = isDeposit ? 'text-tertiary-base' : 'text-error-subtle';

  return (
    <Card outline={color} padding="xs" tabletPadding="s" width="full" background="transparent">
      <Stack size="xxs" tabletSize="s" justify="center">
        <Text color="label-primary-subtle" type="tiny">
          {i18n.t(`pages.home.transaction_info.${type}`)}
        </Text>
        <Text color={color}>
          <Stack line size="xxs">
            <Icon name={isDeposit ? 'arrow-up' : 'arrow-down'} size="s" />
            <PriceDisplay type="secondary" value={value} />
          </Stack>
        </Text>
      </Stack>
    </Card>
  );
}
