import './box.scss';
import React from 'react';
import { Sizes } from '../stack/stack';
import classnames from 'classnames';

interface Padding {
  horizontal?: Sizes;
  vertical?: Sizes;
  top?: Sizes;
  bottom?: Sizes;
  left?: Sizes;
  right?: Sizes;
}

export interface Props {
  padding?: Sizes | Padding;
  tabletPadding?: Sizes | Padding;
  desktopPadding?: Sizes | Padding;
  children: React.ReactNode;
  relative?: boolean;
  hidden?: 'mobile' | 'tablet' | 'desktop';
}

export function Box({ children, relative, padding, tabletPadding, desktopPadding, hidden }: Props): JSX.Element {
  return (
    <div
      className={classnames(
        'box',
        { 'box--relative': relative },
        hidden && `box--hidden-${hidden}`,
        typeof padding === 'string' && `box--padding-${padding}`,
        typeof tabletPadding === 'string' && `box--tablet-padding-${tabletPadding}`,
        typeof desktopPadding === 'string' && `box--desktop-padding-${desktopPadding}`,
        ...(typeof padding === 'object'
          ? Object.keys(padding).map((key) => `box--padding-${key}-${padding[key as keyof Padding]}`)
          : []),
        ...(typeof tabletPadding === 'object'
          ? Object.keys(tabletPadding).map((key) => `box--tablet-padding-${key}-${tabletPadding[key as keyof Padding]}`)
          : []),
        ...(typeof desktopPadding === 'object'
          ? Object.keys(desktopPadding).map((key) => `box--desktop-padding-${key}-${desktopPadding[key as keyof Padding]}`)
          : [])
      )}
    >
      {children}
    </div>
  );
}
