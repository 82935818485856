export function parseDate(value?: string | Date | null): Date | undefined {
  if (!value) {
    return;
  }
  if (typeof value !== 'string') {
    return value;
  }
  const unix = Date.parse(value);
  if (isNaN(unix)) {
    return;
  }
  return new Date(unix);
}

export function timeSensitiveGreeting(): 'morning' | 'afternoon' | 'night' {
  const hour = new Date().getHours();

  if (hour < 12) {
    return 'morning';
  } else if (hour <= 18) {
    return 'afternoon';
  }
  return 'night';
}
