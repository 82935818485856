import './dialog.scss';
import React, { useEffect } from 'react';
import { Stack } from '../stack/stack';
import { Icon } from '../icon/icon';

interface Props {
  open: boolean;
  title?: string;
  children: React.ReactNode;
  onClose?: () => void;
}

export function Dialog({ open, title, children, onClose }: Props): JSX.Element {
  const dialog = React.useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (dialog.current && open) {
      dialog.current.showModal();
    }
  }, [open]);

  function handleOnClose(): void {
    onClose?.();
    dialog.current?.close();
  }

  function handleOnClickOutside(event: React.MouseEvent<HTMLDialogElement>): void {
    const rect = dialog.current?.getBoundingClientRect();

    if (
      rect &&
      (rect.left > event.clientX ||
        rect.right < event.clientX ||
        rect.top > event.clientY ||
        rect.bottom < event.clientY)
    ) {
      dialog.current?.close();
    }
  }

  return (
    <dialog ref={dialog} className="dialog" onClick={handleOnClickOutside}>
      <header className="dialog__header">
        <Stack line size="s" justify="space-between">
          {title && <p className="dialog__title">{title}</p>}
          <div role="button" className="dialog__close" onClick={handleOnClose}>
            <Icon name="close" />
          </div>
        </Stack>
      </header>
      <div className="dialog__body">{children}</div>
    </dialog>
  );
}
