import './key_value_table.scss';
import React, { ReactNode } from 'react';
import { Text } from '../text/text';

interface Props {
  data: Array<{
    label?: ReactNode;
    value?: ReactNode;
  }>;
}

export function KeyValueTable({ data }: Props): JSX.Element {
  return (
    <div className="key-value-table">
      {data.map((item, index) => {
        return (
          <React.Fragment key={`key-value-table__item-${index}`}>
            <Text className="key-value-table__label" type="caption" color="label-primary-subtle">
              {item.label}
            </Text>
            <Text className="key-value-table__value" type="h3">
              {item.value}
            </Text>
          </React.Fragment>
        );
      })}
    </div>
  );
}
