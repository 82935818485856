import { graphql } from '../generated';
import { GraphClient } from './api';

const REQUEST_WITHDRAWAL = graphql(`
  mutation RequestWithdrawal($id: ID!, $value: Float!) {
    requestWithdrawal(input: { id: $id, value: $value }) {
      withdrawal {
        value
      }
      depot {
        pendingTransactions {
          nodes {
            value
            transactionType
          }
        }
      }
    }
  }
`);

export async function requestWithdrawal(client: GraphClient, variables: { id: string; value: number }): Promise<void> {
  await client.mutate({
    mutation: REQUEST_WITHDRAWAL,
    variables,
  });
}
