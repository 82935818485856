import './greeting.scss';
import React from 'react';
import { Container } from '../container/container';
import { Stack } from '../stack/stack';
import { Icon } from '../icon/icon';
import { Text } from '../text/text';

export function Greeting({ name }: { name?: string }): JSX.Element | null {
  if (!name) {
    return <div className="greeting__placeholder"></div>;
  }

  return (
    <div className="greeting">
      <Container>
        <Stack line size="xxxs" tabletSize="xs" tabletJustify="right">
          <Icon name="sun" />
          <Text type="caption">{name}</Text>
        </Stack>
      </Container>
    </div>
  );
}
