/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel-plugin for production.
 */
const documents = {
    "\n  query GetAppHomeData {\n    viewer {\n      email\n      givenName\n      familyName\n      name\n    }\n    depot {\n      id\n      value\n      twrSinceInceptionAbs\n      twrSinceInceptionRel\n      twrYtdAbs\n      twrYtdRel\n      twr1yAbs\n      twr1yRel\n      netInvestmentSinceInception\n      historicalDepotValues {\n        id\n        value\n        calendarDate\n        twrRelPerc\n        twr1yAbs\n        twr1yRel\n        twrSinceInceptionAbs\n        twrSinceInceptionRel\n        twrYtdAbs\n        twrYtdRel\n      }\n    }\n  }\n": types.GetAppHomeDataDocument,
    "\n  query ProfilePage {\n    viewer {\n      email\n      givenName\n      familyName\n      name\n    }\n    depot {\n      id\n      value\n      clearingIban\n      strategy\n      depotNumber\n    }\n  }\n": types.ProfilePageDocument,
    "\n  query GetTransactions {\n    depot {\n      id\n      monthlySummaries {\n        nodes {\n          id\n          amount\n          month\n          transactions {\n            nodes {\n              id\n            }\n          }\n          breakdowns {\n            nodes {\n              id\n              percentage\n              assetClass {\n                id\n                name\n                chartColors\n              }\n            }\n          }\n        }\n      }\n      pendingDeposits {\n        nodes {\n          id\n          createdAt\n          value\n          transactionType\n        }\n      }\n      transactions(first: 10) {\n        nodes {\n          id\n          happenedOn\n          transactionType\n          description\n          amount\n        }\n      }\n    }\n  }\n": types.GetTransactionsDocument,
    "\n  query GetMonthlyDetails($id: ID!) {\n    depot {\n      id\n      monthlySummary(id: $id) {\n        amount\n        month\n        transactions(first: 50) {\n          nodes {\n            id\n            happenedOn\n            transactionType\n            description\n            amount\n          }\n        }\n      }\n    }\n  }\n": types.GetMonthlyDetailsDocument,
    "\n  query GetDocuments {\n    documents {\n      nodes {\n        id\n        author\n        createdAt\n        name\n        read\n        url\n      }\n    }\n  }\n": types.GetDocumentsDocument,
    "\n  mutation RequestDeposit($id: ID!, $value: Float!) {\n    requestDeposit(input: { id: $id, value: $value }) {\n      depot {\n        id\n        pendingDeposits {\n          nodes {\n            id\n            value\n            createdAt\n          }\n        }\n      }\n    }\n  }\n": types.RequestDepositDocument,
    "\n  query GetDepot {\n    depot {\n      id\n      value\n      clearingIban\n      strategy\n      depotNumber\n      twrSinceInceptionAbs\n      twrSinceInceptionRel\n      twr1yAbs\n      twr1yRel\n      twrYtdAbs\n      twrYtdRel\n      netInvestmentSinceInception\n      historicalDepotValues {\n        id\n        calendarDate\n        twr1yAbs\n        twr1yRel\n        twrSinceInceptionAbs\n        twrSinceInceptionRel\n        twrYtdAbs\n        twrYtdRel\n      }\n      portfolioBreakdown {\n        id\n        percentage\n        assets {\n          nodes {\n            id\n            name\n            percentage\n          }\n        }\n        assetClass {\n          id\n          name\n          chartColors\n        }\n      }\n    }\n  }\n": types.GetDepotDocument,
    "\n  query GetDepotValue {\n    depot {\n      value\n    }\n  }\n": types.GetDepotValueDocument,
    "\n  query GetModalTransactions($cursor: String) {\n    depot {\n      id\n      netInvestmentSinceInceptionDeposits\n      netInvestmentSinceInceptionWithdrawals\n      transactions(first: 10, after: $cursor, types: [deposit, withdrawal]) {\n        edges {\n          node {\n            id\n            happenedOn\n            transactionType\n            description\n            amount\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n": types.GetModalTransactionsDocument,
    "\n  query GetViewer {\n    viewer {\n      email\n      givenName\n      familyName\n      name\n    }\n  }\n": types.GetViewerDocument,
    "\n  mutation RequestWithdrawal($id: ID!, $value: Float!) {\n    requestWithdrawal(input: { id: $id, value: $value }) {\n      withdrawal {\n        value\n      }\n      depot {\n        pendingTransactions {\n          nodes {\n            value\n            transactionType\n          }\n        }\n      }\n    }\n  }\n": types.RequestWithdrawalDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetAppHomeData {\n    viewer {\n      email\n      givenName\n      familyName\n      name\n    }\n    depot {\n      id\n      value\n      twrSinceInceptionAbs\n      twrSinceInceptionRel\n      twrYtdAbs\n      twrYtdRel\n      twr1yAbs\n      twr1yRel\n      netInvestmentSinceInception\n      historicalDepotValues {\n        id\n        value\n        calendarDate\n        twrRelPerc\n        twr1yAbs\n        twr1yRel\n        twrSinceInceptionAbs\n        twrSinceInceptionRel\n        twrYtdAbs\n        twrYtdRel\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetAppHomeData {\n    viewer {\n      email\n      givenName\n      familyName\n      name\n    }\n    depot {\n      id\n      value\n      twrSinceInceptionAbs\n      twrSinceInceptionRel\n      twrYtdAbs\n      twrYtdRel\n      twr1yAbs\n      twr1yRel\n      netInvestmentSinceInception\n      historicalDepotValues {\n        id\n        value\n        calendarDate\n        twrRelPerc\n        twr1yAbs\n        twr1yRel\n        twrSinceInceptionAbs\n        twrSinceInceptionRel\n        twrYtdAbs\n        twrYtdRel\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query ProfilePage {\n    viewer {\n      email\n      givenName\n      familyName\n      name\n    }\n    depot {\n      id\n      value\n      clearingIban\n      strategy\n      depotNumber\n    }\n  }\n"): (typeof documents)["\n  query ProfilePage {\n    viewer {\n      email\n      givenName\n      familyName\n      name\n    }\n    depot {\n      id\n      value\n      clearingIban\n      strategy\n      depotNumber\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetTransactions {\n    depot {\n      id\n      monthlySummaries {\n        nodes {\n          id\n          amount\n          month\n          transactions {\n            nodes {\n              id\n            }\n          }\n          breakdowns {\n            nodes {\n              id\n              percentage\n              assetClass {\n                id\n                name\n                chartColors\n              }\n            }\n          }\n        }\n      }\n      pendingDeposits {\n        nodes {\n          id\n          createdAt\n          value\n          transactionType\n        }\n      }\n      transactions(first: 10) {\n        nodes {\n          id\n          happenedOn\n          transactionType\n          description\n          amount\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetTransactions {\n    depot {\n      id\n      monthlySummaries {\n        nodes {\n          id\n          amount\n          month\n          transactions {\n            nodes {\n              id\n            }\n          }\n          breakdowns {\n            nodes {\n              id\n              percentage\n              assetClass {\n                id\n                name\n                chartColors\n              }\n            }\n          }\n        }\n      }\n      pendingDeposits {\n        nodes {\n          id\n          createdAt\n          value\n          transactionType\n        }\n      }\n      transactions(first: 10) {\n        nodes {\n          id\n          happenedOn\n          transactionType\n          description\n          amount\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetMonthlyDetails($id: ID!) {\n    depot {\n      id\n      monthlySummary(id: $id) {\n        amount\n        month\n        transactions(first: 50) {\n          nodes {\n            id\n            happenedOn\n            transactionType\n            description\n            amount\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetMonthlyDetails($id: ID!) {\n    depot {\n      id\n      monthlySummary(id: $id) {\n        amount\n        month\n        transactions(first: 50) {\n          nodes {\n            id\n            happenedOn\n            transactionType\n            description\n            amount\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetDocuments {\n    documents {\n      nodes {\n        id\n        author\n        createdAt\n        name\n        read\n        url\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetDocuments {\n    documents {\n      nodes {\n        id\n        author\n        createdAt\n        name\n        read\n        url\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RequestDeposit($id: ID!, $value: Float!) {\n    requestDeposit(input: { id: $id, value: $value }) {\n      depot {\n        id\n        pendingDeposits {\n          nodes {\n            id\n            value\n            createdAt\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RequestDeposit($id: ID!, $value: Float!) {\n    requestDeposit(input: { id: $id, value: $value }) {\n      depot {\n        id\n        pendingDeposits {\n          nodes {\n            id\n            value\n            createdAt\n          }\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetDepot {\n    depot {\n      id\n      value\n      clearingIban\n      strategy\n      depotNumber\n      twrSinceInceptionAbs\n      twrSinceInceptionRel\n      twr1yAbs\n      twr1yRel\n      twrYtdAbs\n      twrYtdRel\n      netInvestmentSinceInception\n      historicalDepotValues {\n        id\n        calendarDate\n        twr1yAbs\n        twr1yRel\n        twrSinceInceptionAbs\n        twrSinceInceptionRel\n        twrYtdAbs\n        twrYtdRel\n      }\n      portfolioBreakdown {\n        id\n        percentage\n        assets {\n          nodes {\n            id\n            name\n            percentage\n          }\n        }\n        assetClass {\n          id\n          name\n          chartColors\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetDepot {\n    depot {\n      id\n      value\n      clearingIban\n      strategy\n      depotNumber\n      twrSinceInceptionAbs\n      twrSinceInceptionRel\n      twr1yAbs\n      twr1yRel\n      twrYtdAbs\n      twrYtdRel\n      netInvestmentSinceInception\n      historicalDepotValues {\n        id\n        calendarDate\n        twr1yAbs\n        twr1yRel\n        twrSinceInceptionAbs\n        twrSinceInceptionRel\n        twrYtdAbs\n        twrYtdRel\n      }\n      portfolioBreakdown {\n        id\n        percentage\n        assets {\n          nodes {\n            id\n            name\n            percentage\n          }\n        }\n        assetClass {\n          id\n          name\n          chartColors\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetDepotValue {\n    depot {\n      value\n    }\n  }\n"): (typeof documents)["\n  query GetDepotValue {\n    depot {\n      value\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetModalTransactions($cursor: String) {\n    depot {\n      id\n      netInvestmentSinceInceptionDeposits\n      netInvestmentSinceInceptionWithdrawals\n      transactions(first: 10, after: $cursor, types: [deposit, withdrawal]) {\n        edges {\n          node {\n            id\n            happenedOn\n            transactionType\n            description\n            amount\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query GetModalTransactions($cursor: String) {\n    depot {\n      id\n      netInvestmentSinceInceptionDeposits\n      netInvestmentSinceInceptionWithdrawals\n      transactions(first: 10, after: $cursor, types: [deposit, withdrawal]) {\n        edges {\n          node {\n            id\n            happenedOn\n            transactionType\n            description\n            amount\n          }\n        }\n        pageInfo {\n          hasNextPage\n          endCursor\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query GetViewer {\n    viewer {\n      email\n      givenName\n      familyName\n      name\n    }\n  }\n"): (typeof documents)["\n  query GetViewer {\n    viewer {\n      email\n      givenName\n      familyName\n      name\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation RequestWithdrawal($id: ID!, $value: Float!) {\n    requestWithdrawal(input: { id: $id, value: $value }) {\n      withdrawal {\n        value\n      }\n      depot {\n        pendingTransactions {\n          nodes {\n            value\n            transactionType\n          }\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation RequestWithdrawal($id: ID!, $value: Float!) {\n    requestWithdrawal(input: { id: $id, value: $value }) {\n      withdrawal {\n        value\n      }\n      depot {\n        pendingTransactions {\n          nodes {\n            value\n            transactionType\n          }\n        }\n      }\n    }\n  }\n"];

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
**/
export function graphql(source: string): unknown;

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;