import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

export function Auth(): JSX.Element {
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    loginWithRedirect();
  }, []);
  return <div />;
}
