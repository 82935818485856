import React from 'react';
import { i18n } from '../../shared/i18n';
import { Button } from '../button/button';
import { Stack } from '../stack/stack';
import { Text } from '../text/text';

interface Props {
  name?: string;
  secondary?: boolean;
  onLogout?: () => void;
}

export function Logout({ name, secondary, onLogout }: Props): JSX.Element | null {
  return (
    <Stack justify={secondary ? 'center' : 'left'} size={secondary ? 'xxxs' : 's'} reverse={secondary}>
      <Text type="caption" color="label-primary-tint">
        {i18n.t('pages.profile.logged_in_as', {
          name,
        })}
      </Text>
      <Button onClick={onLogout} type="link-secondary" title={i18n.t('pages.profile.logout')} />
    </Stack>
  );
}
