import './navbar.scss';

import { Icon, IconName } from '../icon/icon';
import { Link, useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

import { CollapsePanel } from '../collapse_panel/collapse_panel';
import LaicLogo from './../../public/logos/laic_logo.svg';
import { Logout } from '../logout/logout';
import MbvLogo from './../../public/logos/mbv_logo.svg';
import { Stack } from '../stack/stack';
import classnames from 'classnames';
import { i18n } from '../../shared/i18n';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfig } from '../../shared/config';

const links = ['overview', 'transactions', 'portfolio', 'postbox', 'profile'];

export const GET_NAVAR_DATA = gql(`
  query GetNavbarData {
    viewer {
      name
    }
  }
`);

export function Navbar(): JSX.Element {
  const { logout } = useAuth0();
  const { data } = useQuery(GET_NAVAR_DATA);
  const { branding } = useConfig();
  const [active, setActive] = useState(false);
  const [pending, setPending] = useState(true);

  const location = useLocation();
  const logo = branding === 'laic' ? LaicLogo : MbvLogo;

  return (
    <nav className={classnames('navbar', { 'navbar--active': active, 'navbar--pending': pending })}>
      <section className="navbar__main">
        <Stack justify="center" desktopJustify="left">
          <Link to="/">
            <img src={logo} alt="Laic Logo" className="navbar__logo" />
          </Link>
        </Stack>
        <div className="navbar__menu-toggle">
          <div
            className="navbar__menu-open"
            onClick={() => {
              setPending(false);
              setActive(true);
            }}
          >
            <Icon name="menu" />
          </div>
          <div
            className="navbar__menu-close"
            onClick={() => {
              setPending(false);
              setActive(false);
            }}
          >
            <Icon name="close" />
          </div>
        </div>
      </section>
      <section className="navbar__menu-anchor">
        <div className="navbar__menu-wrapper">
          <CollapsePanel open={active}>
            <section className="navbar__menu">
              <Stack size="xxxxl" align="space-between">
                <ul className="navbar__links">
                  {links.map((link) => {
                    return (
                      <li key={link} onClick={() => setActive(false)}>
                        <Link
                          className={classnames('navbar__link', {
                            'navbar__link--active': location.pathname === `/${link === 'overview' ? '' : link}`,
                          })}
                          to={link === 'overview' ? '/' : `/${link}`}
                        >
                          <Stack size="s" line>
                            <Icon name={link as IconName} size="s" className="navbar__icon" />
                            <span>{i18n.t(`components.tabbar.${link}`)}</span>
                          </Stack>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
                {data?.viewer?.name && (
                  <footer className="navbar__logout">
                    <Logout
                      name={data.viewer.name}
                      onLogout={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                    />
                  </footer>
                )}
              </Stack>
            </section>
          </CollapsePanel>
        </div>
      </section>
    </nav>
  );
}
