import './button.scss';
import React from 'react';
import classnames from 'classnames';
import { useSafeState } from '../../shared/viewhelper';
import { Box } from '../box/box';
import { IconName } from '../icon/icon';
import { Loader } from '../loader/loader';

export type ButtonType = 'primary' | 'secondary' | 'link' | 'link-secondary' | 'outline';

interface Props {
  title?: string;
  type?: ButtonType;
  disabled?: boolean;
  loading?: boolean;
  block?: boolean;
  icon?: IconName;
  iconLeft?: boolean;
  onClick?: () => void | Promise<void>;
  onFocus?: () => void;
}

export function Button({
  title,
  disabled,
  loading: externalLoading,
  block,
  icon,
  iconLeft,
  type = 'primary',
  onClick,
  onFocus,
}: Props): JSX.Element {
  const [clickLoading, setClickLoading] = useSafeState(false);
  const loading = clickLoading || externalLoading;
  const padding = type !== 'link' && type !== 'link-secondary';

  function handleOnClick(event: React.MouseEvent): void {
    if (!disabled && !loading && onClick) {
      event.preventDefault();
      event.stopPropagation();
      setClickLoading(true);
      const promise = onClick();

      if (promise) {
        promise.finally(() => setClickLoading(false));
      } else {
        setClickLoading(false);
      }
    }
  }

  return (
    <button
      className={classnames(
        'button',
        {
          'button--disabled': disabled || loading,
          'button--loading': loading,
          'button--block': block,
          'button--icon-left': iconLeft,
        },
        `button--${type}`,
        icon && `button--icon-${icon}`
      )}
      disabled={loading}
      onClick={handleOnClick}
      title={title}
      onFocus={onFocus}
    >
      <Box
        padding={
          padding
            ? {
                vertical: type === 'secondary' ? 's' : 'xxs',
                horizontal: 's',
              }
            : undefined
        }
        tabletPadding={
          padding
            ? {
                vertical: type === 'secondary' ? 's' : 'xs',
                horizontal: 's',
              }
            : undefined
        }
      >
        {loading && (
          <div className="button__loader">
            <Loader />
          </div>
        )}
        {title && <span className="button__text">{title}</span>}
      </Box>
    </button>
  );
}
