import './container.scss';
import classnames from 'classnames';
import React from 'react';
import { Loader } from '../loader/loader';
import { Error } from '../error/error';
import { Box } from '../box/box';
import { Card } from '../card/card';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';
import { Text } from '../text/text';
import { i18n } from '../../shared/i18n';

interface Props {
  loading?: boolean;
  error?: Error;
  children: React.ReactNode;
  fullWidth?: boolean;
  desktopFullWidth?: boolean;
  title?: string;
}

export function Container({ title, loading, error, children, fullWidth, desktopFullWidth }: Props): JSX.Element {
  const className = classnames('container', {
    'container--full-width': fullWidth,
    'container--desktop-full-width': desktopFullWidth,
    'container--desktop-normal': desktopFullWidth === false,
  });

  let content = children;

  if (loading) {
    content = (
      <Box padding="m">
        <div className="container__loader">
          <Loader />
        </div>
      </Box>
    );
  }

  if (error) {
    content = (
      <Card padding="m">
        <Box padding={{ vertical: 'mega' }}>
          <Stack>
            <Stack justify="center">
              <Text color="text-error-subtle">
                <Icon name="exclamation-circle" />
              </Text>
            </Stack>
            <Stack size="xxs" justify="center">
              <Text type="h1" color="primary-base" align="center">
                {i18n.t('errors.something_went_wrong')}
              </Text>
              <Text type="h2" color="label-primary-subtle" align="center">
                {i18n.t('errors.there_was_an_error')}
              </Text>
            </Stack>
            <Error error={error} justify="center" />
          </Stack>
        </Box>
      </Card>
    );
  }

  return (
    <div className={className}>
      <Stack size="s" tabletSize="xl">
        {title && <Text type="h1">{title}</Text>}
        {content}
      </Stack>
    </div>
  );
}
