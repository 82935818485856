import type { I18n, TranslateOptions } from 'i18n-js';

// something is really off about the i18n-js package, the default exported file crashes jest
import { I18n as I18nInit } from 'i18n-js/dist/require/index.js';
import React from 'react';
import de from './locale/de.json';
import en from './locale/en.json';

type Interpolate = (i18n: I18n, message: string, options: TranslateOptions) => string | JSX.Element;

interface CustomI18n extends Omit<I18n, 'interpolate'> {
  interpolate: Interpolate;
}

export const i18n = new I18nInit({ de, en }) as CustomI18n;

i18n.locale = 'de';
i18n.enableFallback = true;
i18n.defaultLocale = 'de';
i18n.transformKey = (key: string) => key;
i18n.interpolate = (i18n, message, options) => {
  options = Object.keys(options).reduce((buffer, key) => {
    buffer[i18n.transformKey(key)] = options[key];
    return buffer;
  }, {} as TranslateOptions);
  const matches = message.match(i18n.placeholder);

  if (!matches) {
    return message;
  }

  while (matches.length) {
    let value: string;
    const placeholder = matches.shift() as string;
    const name = placeholder.replace(i18n.placeholder, '$1');
    const regex = new RegExp(placeholder.replace(/\{/gm, '\\{').replace(/\}/gm, '\\}'));

    if (options[name] !== undefined && options[name] !== null) {
      if (typeof options[name] === 'string') {
        value = options[name].toString().replace(/\$/gm, '_#$#_');
      } else {
        value = options[name];
      }
    } else if (name in options) {
      value = i18n.nullPlaceholder(i18n, placeholder, message, options);
    } else {
      value = i18n.missingPlaceholder(i18n, placeholder, message, options);
    }

    if (typeof options[name] === 'string') {
      message = message.replace(regex, value);
    } else {
      const [first, second] = message.split(regex);
      return (
        <React.Fragment>
          {first}
          {value}
          {second}
        </React.Fragment>
      );
    }
  }

  return message.replace(/_#\$#_/g, '$');
};

export const formatterLocale = i18n.locale;

export function formatError(error: string): string {
  const errorMessage = i18n.t(`errors.${error}`);

  if (errorMessage.includes('missing') && errorMessage.includes('translation')) {
    return error;
  }
  return errorMessage;
}
