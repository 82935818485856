import React, { useState } from 'react';
import { filterValuesByTimeframe, twr } from '../shared/depot';
import { formatDate, formatPercentage } from '../shared/format';
import { timeframeId, timeframeOptions } from '../shared/timeframe';

import { Box } from '../components/box/box';
import { ButtonBar } from '../components/button_bar/button_bar';
import { Card } from '../components/card/card';
import { Container } from '../components/container/container';
import { Hiddable } from '../components/hiddable/hiddable';
import { HistoricalDepotValue } from '../generated/graphql';
import { LineChart } from '../components/charts/line_chart';
import { PriceDisplay } from '../components/price_display/price_display';
import { PriceVariation } from '../components/price_variation/price_variation';
import { Stack } from '../components/stack/stack';
import { Text } from '../components/text/text';
import { Transact } from '../components/deposit_withdraw/transact';
import { TransactionInfo } from './transaction_info';
import { compact } from 'lodash';
import { graphql } from '../generated';
import { i18n } from '../shared/i18n';
import { useQuery } from '@apollo/client';

const GET_HOME_DATA = graphql(`
  query GetHomeData {
    depot {
      id
      value
      twrSinceInceptionAbs
      twrSinceInceptionRel
      twrYtdAbs
      twrYtdRel
      twr1yAbs
      twr1yRel
      netInvestmentSinceInception
      historicalDepotValues {
        id
        value
        calendarDate
        twrRelPerc
        twr1yAbs
        twr1yRel
        twrSinceInceptionAbs
        twrSinceInceptionRel
        twrYtdAbs
        twrYtdRel
      }
      clearingIban
    }
  }
`);

function getTimeframeRelValue(selectedTimeframe: timeframeId): keyof Omit<HistoricalDepotValue, '__typename'> {
  switch (selectedTimeframe) {
    case 'twr1y':
      return 'twr1yRel';
    case 'twrYtd':
      return 'twrYtdRel';
    case 'twrSinceInception':
      return 'twrSinceInceptionRel';
    default:
      return 'twrSinceInceptionRel';
  }
}

export function Home(): JSX.Element {
  const { loading, error, data } = useQuery(GET_HOME_DATA);
  const [selectedTimeframe, setSelectedTimeframe] = useState<timeframeId>('twrYtd');
  const [selectedPoint, setSelectedPoint] = useState<HistoricalDepotValue | null>(null);

  const currentTitle = selectedPoint ? formatDate(selectedPoint.calendarDate) : i18n.t('pages.home.depot_value');
  const currentValue = selectedPoint ? selectedPoint.value : data?.depot?.value;
  const difference = twr({
    timeframe: selectedTimeframe,
    ...(selectedPoint ?? data?.depot),
  });

  const filteredValues = filterValuesByTimeframe(selectedTimeframe, data?.depot?.historicalDepotValues);

  return (
    <>
      <Container title={i18n.t('pages.home.overview')} loading={loading} error={error}>
        <Box
          padding={{
            bottom: 'xxxl',
          }}
        >
          <Stack size="s" tabletSize="m">
            <Card overflow="hidden">
              <Stack>
                <Stack tabletSize="s">
                  <Box
                    padding={{
                      top: 's',
                      horizontal: 'm',
                    }}
                    tabletPadding={{
                      top: 'l',
                      horizontal: 'xl',
                    }}
                  >
                    <Stack size="s" tabletLine>
                      <Stack size="xxxs">
                        <Text type="caption" color="label-primary-subtle">
                          {currentTitle}
                        </Text>
                        <PriceDisplay value={currentValue} />
                        <PriceVariation {...difference} />
                      </Stack>
                      <Stack justify="left" tabletJustify="right">
                        <Hiddable hidden={!!selectedPoint}>
                          <Transact displayTransaction="deposit" clearingIban={data?.depot?.clearingIban} />
                        </Hiddable>
                      </Stack>
                    </Stack>
                  </Box>
                  <LineChart
                    data={filteredValues.map((item) => ({
                      label: item.calendarDate,
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      value: (item as any)[getTimeframeRelValue(selectedTimeframe)],
                      labelFormatter: (val) => formatDate(val) || val,
                      valueFormatter: (val) => formatPercentage(Number(val)) || val,
                    }))}
                    onClick={(label) => {
                      if (label === null) return setSelectedPoint(null);

                      const point = data?.depot?.historicalDepotValues.find((item) => item.calendarDate === label);
                      if (point) {
                        setSelectedPoint(point);
                      }
                    }}
                    active={!!selectedPoint}
                    selected={selectedPoint?.calendarDate}
                    labels={compact([
                      formatDate(data?.depot?.historicalDepotValues[0].calendarDate),
                      formatDate(
                        data?.depot?.historicalDepotValues[data?.depot?.historicalDepotValues.length - 1].calendarDate
                      ),
                    ])}
                  />
                </Stack>
                <Box
                  padding={{
                    bottom: 's',
                    horizontal: 'm',
                  }}
                  tabletPadding={{
                    bottom: 'l',
                    horizontal: 'xl',
                  }}
                >
                  <Stack desktopJustify="left">
                    <ButtonBar
                      activeId={selectedTimeframe}
                      items={timeframeOptions.map((item) => ({
                        ...item,
                        onClick: () => setSelectedTimeframe(item.id),
                      }))}
                    />
                  </Stack>
                </Box>
              </Stack>
            </Card>
            <TransactionInfo value={data?.depot?.netInvestmentSinceInception} />
          </Stack>
        </Box>
      </Container>
    </>
  );
}
