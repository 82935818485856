import './index.scss';
import React, { useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './app';
import { ApolloProvider } from '@apollo/client';
import { GraphClient, createClient } from './models/api';
import { ConfigProvider, getConfig } from './shared/config';
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';

export const testToken = new URLSearchParams(window.location.search).get('token');

function Content(): JSX.Element {
  const { getAccessTokenSilently } = useAuth0();
  const clientRef = useRef<GraphClient>();
  if (!clientRef.current) {
    clientRef.current = createClient('/graphql', testToken ? async () => testToken : getAccessTokenSilently);
  }
  return (
    <ApolloProvider client={clientRef.current}>
      <App />
    </ApolloProvider>
  );
}

async function start(): Promise<void> {
  const branding =
    (document.querySelector("meta[name='laic-branding']") as HTMLMetaElement | undefined)?.content ?? null;
  const config = await getConfig(branding);
  
  createRoot(document.getElementById('root') as HTMLElement).render(
    <ConfigProvider value={config}>
      <Auth0Provider
        domain={config.auth0.domain}
        clientId={config.auth0.clientId}
        authorizationParams={{
          redirect_uri: location.protocol + '//' + location.host + '/auth',
          audience: config.auth0.audience,
        }}
        cacheLocation="localstorage"
      >
        <BrowserRouter>
          <Content />
        </BrowserRouter>
      </Auth0Provider>
    </ConfigProvider>
  );
}

start();
