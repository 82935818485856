import { useApolloClient, useQuery } from '@apollo/client';
import React from 'react';
import { Container } from '../components/container/container';
import { List } from '../components/list/list';
import { Stack } from '../components/stack/stack';
import { Text } from '../components/text/text';
import { graphql } from '../generated';
import { Document } from '../generated/graphql';
import { i18n } from '../shared/i18n';
import { PostboxDocument } from '../components/documents_list/postbox_document';
import { Badge } from '../components/badge/badge';
import { updateCache } from '../models/api';

const GET_DOCUMENTS = graphql(`
  query GetDocuments {
    documents {
      nodes {
        id
        author
        createdAt
        name
        read
        url
      }
    }
  }
`);

export function Postbox(): JSX.Element {
  const client = useApolloClient();
  const { loading, error, data } = useQuery<{ documents?: { nodes: Document[] } }>(GET_DOCUMENTS);

  function handleOnOpen(document: Document): void {
    updateCache(client, document, { read: true });
  }

  return (
    <Container loading={loading} error={error} fullWidth desktopFullWidth={false}>
      <Stack size="s" tabletSize="xl">
        <Container desktopFullWidth>
          <Text type="h1">{i18n.t('pages.postbox.postbox')}</Text>
        </Container>
        <List
          emptyMessage={i18n.t('pages.postbox.no_documents')}
          align="top"
          items={
            data?.documents?.nodes?.map((item) => {
              return {
                id: item.id,
                content: <PostboxDocument item={item} onOpen={() => handleOnOpen(item)} />,
                actions: !item.read ? <Badge /> : undefined,
              };
            }) ?? []
          }
        />
      </Stack>
    </Container>
  );
}
