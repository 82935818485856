import { useQuery } from '@apollo/client';
import React from 'react';
import { Card } from '../components/card/card';
import { PieChart } from '../components/charts/pie_chart';
import { Container } from '../components/container/container';
import { Stack } from '../components/stack/stack';
import { Text } from '../components/text/text';
import { graphql } from '../generated';
import { i18n } from '../shared/i18n';
import { AssetDropdown } from '../components/asset_dropdown/asset_dropdown';
import { PortfolioBreakdown } from '../generated/graphql';

const GET_PORTFOLIO_DATA = graphql(`
  query GetPortfolioData {
    depot {
      portfolioBreakdown {
        id
        percentage
        assets {
          nodes {
            id
            name
            percentage
          }
        }
        assetClass {
          id
          name
          chartColors
        }
      }
    }
  }
`);

export function Portfolio(): JSX.Element {
  const { loading, error, data } = useQuery(GET_PORTFOLIO_DATA);

  const portfolioData =
    data?.depot?.portfolioBreakdown.map((item) => {
      return {
        id: item.id,
        label: item.assetClass.name,
        value: item.percentage,
        color: item.assetClass.chartColors,
      };
    }) ?? [];

  return (
    <Container title={i18n.t('pages.portfolio.portfolio')} loading={loading} error={error}>
      <Stack>
        <Card padding="m" tabletPadding="xl">
          <Stack size="xl">
            {portfolioData.length ? (
              <PieChart
                data={portfolioData}
                showLabels
                stackProps={{
                  tabletLine: true,
                  tabletSize: 'mega',
                  tabletJustify: 'center',
                }}
              />
            ) : (
              <Text type="h3">{i18n.t('pages.portfolio.no_data_yet')}</Text>
            )}
          </Stack>
        </Card>

        <Stack size="xs">
          <Text type="h2-strong" color="label-primary-base" uppercase>
            {i18n.t('pages.portfolio.breakdown')}
          </Text>
          {data?.depot?.portfolioBreakdown.map((breakdown) => (
            <AssetDropdown key={breakdown.id} asset={breakdown as PortfolioBreakdown} />
          ))}
        </Stack>
      </Stack>
    </Container>
  );
}
