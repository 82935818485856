import './price_variation.scss';
import React from 'react';
import classnames from 'classnames';
import { formatCurrency, formatPercentage } from '../../shared/format';
import { Icon } from '../icon/icon';
import { Stack } from '../stack/stack';
import { Text } from '../text/text';

interface Props {
  value?: number;
  percentage?: number;
  hidePercentage?: boolean;
  negative?: boolean;
  theme?: 'deposit';
}

export function PriceVariation({ value = 0, percentage = 0, hidePercentage, negative, theme }: Props): JSX.Element {
  return (
    <div
      className={classnames('price-variation', {
        'price-variation--positive': value > 0,
        'price-variation--negative': negative || value < 0,
        'price-variation--theme-deposit': theme === 'deposit',
      })}
    >
      <Stack line size="xxxs">
        {value !== 0 && theme === 'deposit' ? <Icon name="arrow-down" size="s" /> : <Icon name="slant-arrow" />}
        <Text type="h3-strong">
          {formatCurrency(value)} {!hidePercentage && <>({formatPercentage(percentage)})</>}
        </Text>
      </Stack>
    </div>
  );
}
