import './collapse_panel.scss';
import React from 'react';
import classnames from 'classnames';

type RenderFunction = () => React.ReactNode;

export interface Props {
  open?: boolean;
  children: React.ReactNode | RenderFunction;
}

export function CollapsePanel({ open, children }: Props): JSX.Element {
  return (
    <div className={classnames('collapse-panel', { 'collapse-panel--open': open })}>
      <div className="collapse-panel__content">{typeof children === 'function' ? children() : children}</div>
    </div>
  );
}
