import './line_chart.scss';

import { Area, AreaChart, ReferenceLine, ResponsiveContainer, XAxis } from 'recharts';

import { ChartValue } from './chart';
import React from 'react';
import { Stack } from '../stack/stack';
import classnames from 'classnames';
import { smoothLine } from '../../shared/smooth-line';

interface Props {
  data: ChartValue[];
  selected?: string | null;
  onClick?: (label: string | null) => void;
  labels?: string[];
  active?: boolean;
}

export function LineChart({ data, selected, active, onClick, labels }: Props): JSX.Element | null {
  const index = data?.findIndex((item) => item.label === selected) || 0;
  const selectedIndex = index > -1 ? index : data?.length ?? 0;

  const smoothValues = smoothLine(data.map((d) => Number(d.value)));

  const smoothData = data.map((d, idx) => ({ ...d, value: smoothValues[idx] }));

  const splittedData = smoothData.map((item, index) => {
    return {
      ...item,
      before: index <= selectedIndex ? item.value : undefined,
      after: index >= selectedIndex ? item.value : undefined,
    };
  });

  return (
    <div
      className={classnames('line-chart', {
        'line-chart--active': active,
      })}
    >
      <div className="line-chart__wrapper">
        <div className="line-chart__chart">
          <ResponsiveContainer width="100%">
            <AreaChart
              data={splittedData}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
              onMouseMove={(e) => {
                e?.activeLabel && onClick?.(e.activeLabel);
              }}
              onMouseLeave={() => {
                onClick?.(null);
              }}
            >
              <defs>
                <linearGradient id="colorActive" gradientTransform="rotate(90)">
                  <stop offset="5%" stopColor="var(--chart-gradient-top)" />
                  <stop offset="95%" stopColor="var(--chart-gradient-bottom)" />
                </linearGradient>
                <linearGradient id="colorInactive" gradientTransform="rotate(90)">
                  <stop offset="5%" stopColor="var(--chart-gradient-top-inactive)" />
                  <stop offset="95%" stopColor="var(--chart-gradient-bottom-inactive)" />
                </linearGradient>
              </defs>
              <Area
                type="monotone"
                dataKey="before"
                stroke="var(--color-background-chart-primary-base)"
                fill="url(#colorActive)"
                isAnimationActive={false}
              />
              <Area
                type="monotone"
                dataKey="after"
                stroke="var(--color-background-chart-primary-secondary)"
                fill="url(#colorInactive)"
                isAnimationActive={false}
              />
              {selectedIndex > -1 && <ReferenceLine x={data?.[selectedIndex]?.label} />}
              <XAxis dataKey="label" stroke="var(--labels-color)" className="line-chart__axis" tick={false} />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <div className="line-chart__labels">
          <Stack line justify="space-between">
            {labels?.map((item) => {
              return (
                <div className="line-chart__label" key={item}>
                  {item}
                </div>
              );
            })}
          </Stack>
        </div>
      </div>
    </div>
  );
}
