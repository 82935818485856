import React from 'react';
import { Button } from '../button/button';
import { Stack } from '../stack/stack';
import { Success } from '../sucess/success';
import { Text } from '../text/text';

interface Props {
  onClose: () => void;
  text: {
    title: string;
    processing: string;
    review?: string;
    done: string;
  };
}

export function TransactionComplete({ onClose, text }: Props): JSX.Element {
  return (
    <Stack align="space-between">
      <Stack>
        <Stack size="s">
          <Success />
          <Text type="h1">{text.title}</Text>
        </Stack>
        <Text color="label-primary-subtle">{text.processing}</Text>
      </Stack>
      <Stack>
        {text?.review && (
          <Text type="caption" color="label-primary-tint">
            {text.review}
          </Text>
        )}
        <Button onClick={onClose} type="secondary" title={text.done} />
      </Stack>
    </Stack>
  );
}
