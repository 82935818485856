import { useQuery } from '@apollo/client';
import React from 'react';
import { graphql } from '../generated';
import { List } from '../components/list/list';
import { TransactionCell } from '../components/transaction/transaction_cell';

const GET_DETAILS = graphql(`
  query GetMonthlyDetails($id: ID!) {
    depot {
      id
      monthlySummary(id: $id) {
        transactions(first: 50) {
          nodes {
            id
            happenedOn
            transactionType
            description
            amount
          }
        }
      }
    }
  }
`);

export function MonthlyTransactions({ id }: { id: string }): JSX.Element {
  const { data } = useQuery(GET_DETAILS, { variables: { id } });

  return (
    <List
      theme="cards"
      items={
        data?.depot?.monthlySummary.transactions.nodes?.map((node) => ({
          id: node.id,
          content: <TransactionCell transaction={node} hideYear />,
        })) ?? []
      }
    />
  );
}
