import './stacked_bar_chart.scss';
import React from 'react';
import { ChartValue } from './chart';
import { Stack } from '../stack/stack';
import { ChartLabels } from './chart_labels';
import classnames from 'classnames';

interface Props {
  data: ChartValue[];
  small?: boolean;
}

export function StackedBarChart({ data, small }: Props): JSX.Element {
  return (
    <div
      className={classnames('stacked-bar-chart', {
        'stacked-bar-chart--small': small,
      })}
    >
      <Stack>
        <ul className="stacked-bar-chart__container">
          {data.map((item) => {
            const color = typeof item.color === 'string' ? item.color : item.color?.[0];

            return (
              <li
                className="stacked-bar-chart__section"
                key={item.label}
                style={{ width: `${(item.value || 0) * 100}%`, backgroundColor: color }}
              ></li>
            );
          })}
        </ul>
        <ChartLabels data={data} secondary />
      </Stack>
    </div>
  );
}
