import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '../components/box/box';
import { Dialog } from '../components/dialog/dialog';
import { Greeting } from '../components/greeting/greeting';
import { Navbar } from '../components/navbar/navbar';
import { Stack } from '../components/stack/stack';
import { Text } from '../components/text/text';
import { useConfig } from '../shared/config';
import { i18n } from '../shared/i18n';

// LAIC
import AppQR from './../../src/public/app-qr.svg';
import Download from './../../src/public/download.svg';

interface Props {
  userHadActivity: () => void;
  greetingName?: string;
  greetingTime?: 'morning' | 'afternoon' | 'night';
}

export function MainLayout({ userHadActivity, greetingName, greetingTime }: Props): JSX.Element {
  const { appDialogEnabled } = useConfig();
  const isSafari = !!window.navigator.userAgent.match(/iPhone/i) && !!window.navigator.userAgent.match(/Safari/i);

  return (
    <div className="app" onMouseDown={userHadActivity} onKeyDown={userHadActivity}>
      <Navbar />
      <Box padding={{ vertical: 's' }} tabletPadding={{ vertical: 'xl' }} relative>
        <Stack size="s" tabletSize="xxs">
          <Greeting name={greetingName && i18n.t(`pages.home.greeting.${greetingTime}`, { name: greetingName })} />
          <Outlet />
        </Stack>
      </Box>
      {appDialogEnabled && !isSafari && (
        <Dialog open>
          <Stack size="s">
            <img src={Download} alt="QR code" width={40} />
            <Stack>
              <Text type="h1" uppercase color="primary-base">
                {i18n.t('app-download.title')}
              </Text>
              <Text type="paragraph" color="label-primary-subtle">
                {i18n.t('app-download.description')}
              </Text>
              <Stack justify="center">
                <img src={AppQR} alt="QR code" width={150} />
              </Stack>
              <Text type="tiny" color="label-primary-subtle">
                {i18n.t('app-download.footer')}
              </Text>
              <span />
            </Stack>
          </Stack>
        </Dialog>
      )}
    </div>
  );
}
