import './stack.scss';
import classnames from 'classnames';
import React from 'react';

export type Sizes = 'zero' | 'xxxs' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl' | 'xxxl' | 'xxxxl' | 'mega';

type Justify = 'left' | 'center' | 'right' | 'space-between' | 'space-around';
type Align = 'top' | 'center' | 'bottom' | 'space-between';

export interface Props {
  children?: React.ReactNode;
  size?: Sizes;
  tabletSize?: Sizes;
  desktopSize?: Sizes;
  line?: boolean;
  tabletLine?: boolean;
  desktopLine?: boolean;
  align?: Align;
  justify?: Justify;
  tabletJustify?: Justify;
  desktopJustify?: Justify;
  reverse?: boolean;
  inline?: boolean;
}

export function Stack({
  children,
  size,
  tabletSize,
  desktopSize,
  line,
  tabletLine,
  desktopLine,
  align,
  justify,
  tabletJustify,
  desktopJustify,
  reverse,
  inline,
}: Props): JSX.Element {
  return (
    <div
      className={classnames(
        'stack',
        size && `stack--${size}`,
        tabletSize && `stack--tablet-${tabletSize}`,
        desktopSize && `stack--desktop-${desktopSize}`,
        align && `stack--align-${align}`,
        justify && `stack--justify-${justify}`,
        tabletJustify && `stack--tablet-justify-${tabletJustify}`,
        desktopJustify && `stack--desktop-justify-${desktopJustify}`,
        { 'stack--line': line },
        { 'stack--tablet-line': tabletLine },
        { 'stack--desktop-line': desktopLine },
        { 'stack--reverse': reverse },
        { 'stack--inline': inline }
      )}
    >
      {children}
    </div>
  );
}
