import './list.scss';
import classnames from 'classnames';
import React, { ReactNode, useState } from 'react';
import { Icon, IconName } from '../icon/icon';
import { i18n } from '../../shared/i18n';
import { CollapsePanel } from '../collapse_panel/collapse_panel';

interface Props {
  items: Array<{
    id: string;
    icon?: IconName;
    content?: ReactNode;
    actions?: ReactNode;
    collapsibleContent?: ReactNode;
    href?: string;
  }>;
  fullWidth?: boolean;
  align?: 'top' | 'center' | 'bottom';
  hover?: boolean;
  emptyMessage?: string;
  theme?: 'cards';
}

export function List({ items, fullWidth, align, hover = true, emptyMessage, theme }: Props): JSX.Element {
  const [expanded, setExpanded] = useState<Record<string, boolean>>();

  if (!items.length) {
    return <div className="list list--empty">{emptyMessage || i18n.t('components.list.no_items')}</div>;
  }

  return (
    <ul
      className={classnames('list', { 'list--hover': hover }, align && `list--align-${align}`, {
        'list--full-width': fullWidth,
        'list--cards': theme === 'cards',
      })}
    >
      {items?.map((item) => {
        const renderContent = (
          <>
            {item.icon && (
              <div className="list__icon">
                <Icon name={item.icon} size="s" color="primary-base"></Icon>
              </div>
            )}
            {item.content && <div className="list__content">{item.content}</div>}
          </>
        );

        return (
          <li key={item.id} className="list__container">
            <div
              className={classnames('list__item', {
                'list__item--expanded': expanded?.[item.id],
                'list__item--collapsible': item?.collapsibleContent,
              })}
              onClick={() => {
                if (item?.collapsibleContent) {
                  setExpanded((expanded) => ({
                    ...expanded,
                    [item.id]: !expanded?.[item.id],
                  }));
                }
              }}
            >
              {item?.href && !item?.collapsibleContent ? (
                <a href={item?.href} target="__blank" rel="noreferrer" className="list__link">
                  {renderContent}
                  {item.actions && <div className="list__actions">{item.actions}</div>}
                </a>
              ) : (
                <>
                  {renderContent}
                  <div className="list__actions">
                    {item?.collapsibleContent ? (
                      <Icon className="list__action-icon" name="chevron-right" size="s" color="primary-base" />
                    ) : (
                      item.actions
                    )}
                  </div>
                </>
              )}
            </div>
            {item?.collapsibleContent && (
              <div className="list__collapsible-content">
                <CollapsePanel open={expanded?.[item.id]}>{item?.collapsibleContent}</CollapsePanel>
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
}
