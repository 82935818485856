import { Props as ModalProps, useModal } from '../components/modal/modal';
import React, { useEffect, useState } from 'react';

import { Card } from '../components/card/card';
import { Container } from '../components/container/container';
import { GET_MODAL_TRANSACTIONS } from '../shared/depot';
import { Icon } from '../components/icon/icon';
import { List } from '../components/list/list';
import { Loader } from '../components/loader/loader';
import { NetInvestment } from '../components/net_investment/net_investment';
import { PriceDisplay } from '../components/price_display/price_display';
import { Stack } from '../components/stack/stack';
import { Text } from '../components/text/text';
import { Transaction } from '../generated/graphql';
import { TransactionCell } from '../components/transaction/transaction_cell';
import { i18n } from '../shared/i18n';
import { useQuery } from '@apollo/client';

interface Props {
  value?: number | null;
}

export function TransactionInfo({ value }: Props): JSX.Element {
  const { error, data, fetchMore } = useQuery(GET_MODAL_TRANSACTIONS);
  const [fetchingMoreData, setFetchingMoreData] = useState(false);
  const edges = data?.depot?.transactions?.edges ?? [];
  const transactions = edges.filter((edge) => !!edge?.node).map((edge) => edge.node as Transaction);
  const { netInvestmentSinceInceptionDeposits, netInvestmentSinceInceptionWithdrawals } = data?.depot || {};

  const modal: ModalProps = {
    title: (
      <Text type="h1" color="primary-base">
        {i18n.t('pages.home.net_investing')}
      </Text>
    ),
    children: () => (
      <Stack>
        {typeof netInvestmentSinceInceptionDeposits === 'number' &&
          typeof netInvestmentSinceInceptionWithdrawals === 'number' && (
            <Stack line>
              <NetInvestment type="deposit" value={netInvestmentSinceInceptionDeposits} />
              <NetInvestment type="withdrawal" value={netInvestmentSinceInceptionWithdrawals} />
            </Stack>
          )}
        {transactions.length > 0 ? (
          <List
            theme="cards"
            items={
              transactions.map((transaction) => {
                return {
                  id: transaction.id,
                  content: <TransactionCell transaction={transaction} hideDescription modalLayout coloredDisplay />,
                };
              }) ?? []
            }
          />
        ) : (
          <Card padding="mega" tabletPadding="xxxxl" desktopPadding="mega" background="transparent">
            <Stack size="xxs" tabletSize="s" align="center" justify="center">
              <Icon name="transaction" />
              <Text type="caption" color="label-primary-subtle" align="center">
                {i18n.t('pages.home.transaction_info.no_items')}
              </Text>
            </Stack>
          </Card>
        )}
        {fetchingMoreData && (
          <Stack justify="center">
            <Loader />
          </Stack>
        )}
      </Stack>
    ),
    onContentScroll: (event) => {
      const target = event.currentTarget;
      const bottom = target.scrollTop + target.clientHeight >= target.scrollHeight - 20;
      const pageInfo = data?.depot?.transactions?.pageInfo;

      if (bottom && !fetchingMoreData && pageInfo?.hasNextPage) {
        setFetchingMoreData(true);
        fetchMore({
          variables: {
            cursor: pageInfo?.endCursor,
          },
        }).finally(() => setFetchingMoreData(false));
      }
    },
  };
  const { updateModal, isOpen } = useModal(modal, 'transaction-info');

  useEffect(() => {
    if (isOpen && data) {
      updateModal(modal, true);
    }
  }, [data]);

  useEffect(() => {
    // show loader when fetching more data
    if (fetchingMoreData) {
      updateModal(modal, true);
    }
  }, [fetchingMoreData]);

  return (
    <Container error={error} fullWidth>
      <Card
        padding="m"
        tabletPadding={{
          vertical: 'm',
          horizontal: 'xl',
        }}
      >
        <Stack line justify="space-between">
          <Text color="label-primary-subtle" type="h3">
            {i18n.t('pages.home.net_investing')}
          </Text>
          <div>
            <Stack line size="s">
              <PriceDisplay type="secondary" value={value} />
              <div
                onClick={() => {
                  updateModal(modal, true);
                }}
              >
                <Icon name="info" color="info-primary-base" />
              </div>
            </Stack>
          </div>
        </Stack>
      </Card>
    </Container>
  );
}
