import { parseDate } from './date';
import { formatterLocale } from './i18n';
const locale = formatterLocale;

export function formatPercentage(
  value?: number | null,
  { maximumFractionDigits }: { maximumFractionDigits?: number } = {
    maximumFractionDigits: 1,
  }
): string | undefined {
  if (value === undefined || value === null || isNaN(value)) {
    return;
  }
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits,
  }).format(value);
}

export function formatCurrency(
  value?: number | null,
  { maximumFractionDigits }: { maximumFractionDigits?: number } = {
    maximumFractionDigits: 2,
  }
): string | undefined {
  if (value === undefined || value === null) {
    return;
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits,
  }).format(value);
}

export function parseNumber(val: string): number | undefined {
  val = val.replace('.', '').replace(',', '.');
  const num = parseFloat(val);
  if (isNaN(num)) {
    return undefined;
  }
  return num;
}

export function formatNumber(
  value?: number | null,
  {
    maximumFractionDigits,
    shorten,
    minimumFractionDigits,
  }: {
    maximumFractionDigits?: number;
    shorten?: 'thousand';
    minimumFractionDigits?: number;
  } = {}
): string {
  if (value === undefined || value === null || isNaN(value)) {
    return '';
  }

  const ONE_THOUSAND = 1000;
  if (shorten === 'thousand' && value >= ONE_THOUSAND) {
    return `${new Intl.NumberFormat(locale).format(value / ONE_THOUSAND)}k`;
  }

  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: maximumFractionDigits ?? 20,
    minimumFractionDigits: minimumFractionDigits ?? 0,
  }).format(value);
}

export function formatIban(value?: string | null): string | null {
  if (!value) return null;
  return value
    .replace(/[ ]/g, '')
    .toUpperCase()
    .replace(/(.{4})/g, '$1 ')
    .trim();
}

export function formatLastFourDigitsIban(value?: string | null): string {
  if (!value) return '';
  return value.replace(/[ ]/g, '').trim().slice(-4);
}

export function formatDate(value?: Date | string | null, options: Intl.DateTimeFormatOptions = {}): string | undefined {
  value = parseDate(value);
  if (value === undefined || value === null) {
    return;
  }
  options = { month: '2-digit', day: '2-digit', year: 'numeric', ...options };
  const date = new Intl.DateTimeFormat(locale, options).format(value);

  if (date.charAt(date.length - 1) === '.') {
    return date.substring(0, date.length - 1);
  }

  return date;
}

export function formatTime(value?: Date | string | null, options: Intl.DateTimeFormatOptions = {}): string | undefined {
  value = parseDate(value);
  if (value === undefined || value === null) {
    return;
  }
  options = {
    hour: '2-digit',
    minute: '2-digit',
    ...options,
  };
  return new Intl.DateTimeFormat(locale, options).format(value);
}

export function formatDateTime(
  value?: Date | string | null,
  options: Intl.DateTimeFormatOptions = {}
): string | undefined {
  value = parseDate(value);
  if (value === undefined || value === null) {
    return;
  }
  options = {
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    ...options,
  };
  const date = new Intl.DateTimeFormat(locale, options).format(value);

  if (date.includes('.,')) {
    return date.replace('.,', ',');
  }

  return date;
}

export function formatMonth(
  value?: Date | string | null,
  options: Intl.DateTimeFormatOptions = {}
): string | undefined {
  value = parseDate(value);
  if (value === undefined || value === null) {
    return;
  }
  options = { month: 'long', year: 'numeric', ...options };
  return new Intl.DateTimeFormat(locale, options).format(value);
}

export function formatRelativeDate(dateString: Date | string): string | undefined {
  const date = parseDate(dateString);
  if (!date) return undefined;
  const formatter = new Intl.RelativeTimeFormat(locale, {
    numeric: 'auto',
  });
  let duration = (date.getTime() - new Date().getTime()) / 1000;

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    // return full date for more than a week
    if (i > 3) {
      return formatDate(date);
    }
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name);
    }
    duration /= division.amount;
  }
  return formatDate(date);
}

const DIVISIONS: Array<{ amount: number; name: Intl.RelativeTimeFormatUnit }> = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' },
];
