import { useEffect, useRef, useState } from 'react';

export function useSafeState<T>(initialState: T): [T, (state: T) => void] {
  const [loading, setLoading] = useState(initialState);
  const mounted = useRef(false);

  function handleSetLoading(state: T): void {
    if (mounted.current) {
      setLoading(state);
    }
  }

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return [loading, handleSetLoading];
}
