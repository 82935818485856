import './pill.scss';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export function Pill({ children }: Props): JSX.Element {
  return <div className="pill">{children}</div>;
}
