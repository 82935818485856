import { Field } from '@nerdgeschoss/react-use-form-library/dist/field';
import React, { useState } from 'react';
import { formatNumber } from '../../shared/format';
import { TextField } from './textfield';

interface Props extends Partial<Field<number | undefined>> {
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  description?: string;
  adornment?: React.ReactNode;
  format?: boolean;
  ariaLabel?: string;
}

export function Numberfield(props: Props): JSX.Element {
  const [localValue, setLocalValue] = useState<string>();

  return (
    <TextField
      {...props}
      value={
        localValue ??
        (props.value !== undefined ? (props.format ? formatNumber(props.value) : props.value.toString()) : '')
      }
      onChange={(val) => {
        setLocalValue(val ?? '');
        const value = Number(val);
        props.onChange?.(isNaN(value) ? 0 : value);
      }}
      onBlur={() => {
        setLocalValue(undefined);
        props.onBlur?.();
      }}
    />
  );
}
