import { useEffect, useRef } from 'react';

const twelve_minutes = 12 * 60 * 1000;

export function useAutoLogout({ logout }: { logout: () => void }): () => void {
  const lastActivityRef = useRef(new Date());
  useEffect(() => {
    const handle = setInterval(() => {
      if (new Date().getTime() - lastActivityRef.current.getTime() > twelve_minutes) {
        clearInterval(handle);
        logout();
      }
    }, 1000);
    return () => {
      clearInterval(handle);
    };
  }, []);
  return () => {
    lastActivityRef.current = new Date();
  };
}
