import { graphql } from '../generated';
import { timeframeId } from './timeframe';
import { GraphClient } from './api';

const REQUEST_DEPOSIT = graphql(`
  mutation RequestDeposit($id: ID!, $value: Float!) {
    requestDeposit(input: { id: $id, value: $value }) {
      depot {
        id
        pendingDeposits {
          nodes {
            id
            value
            createdAt
          }
        }
      }
    }
  }
`);

export async function requestDeposit(client: GraphClient, variables: { id: string; value: number }): Promise<void> {
  await client.mutate({
    mutation: REQUEST_DEPOSIT,
    variables,
  });
}

export const GET_DEPOT = graphql(`
  query GetDepot {
    depot {
      id
      value
      clearingIban
      strategy
      depotNumber
      twrSinceInceptionAbs
      twrSinceInceptionRel
      twr1yAbs
      twr1yRel
      twrYtdAbs
      twrYtdRel
      netInvestmentSinceInception
      historicalDepotValues {
        id
        calendarDate
        twr1yAbs
        twr1yRel
        twrSinceInceptionAbs
        twrSinceInceptionRel
        twrYtdAbs
        twrYtdRel
      }
      portfolioBreakdown {
        id
        percentage
        assets {
          nodes {
            id
            name
            percentage
          }
        }
        assetClass {
          id
          name
          chartColors
        }
      }
    }
  }
`);

export const GET_DEPOT_VALUE = graphql(`
  query GetDepotValue {
    depot {
      value
    }
  }
`);

export const GET_MODAL_TRANSACTIONS = graphql(`
  query GetModalTransactions($cursor: String) {
    depot {
      id
      netInvestmentSinceInceptionDeposits
      netInvestmentSinceInceptionWithdrawals
      transactions(first: 10, after: $cursor, types: [deposit, withdrawal]) {
        edges {
          node {
            id
            happenedOn
            transactionType
            description
            amount
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`);

export function twr(
  data?: {
    timeframe: timeframeId;
    twrSinceInceptionAbs?: number | null;
    twrSinceInceptionRel?: number | null;
    twrYtdAbs?: number | null;
    twrYtdRel?: number | null;
    twr1yAbs?: number | null;
    twr1yRel?: number | null;
  } | null
): { value?: number; percentage?: number } {
  if (!data) return {};
  switch (data.timeframe) {
    case 'twrSinceInception':
      return {
        value: data.twrSinceInceptionAbs || 0,
        percentage: data.twrSinceInceptionRel || 0,
      };
    case 'twrYtd':
      return {
        value: data.twrYtdAbs ?? 0,
        percentage: data.twrYtdRel ?? 0,
      };
    case 'twr1y':
      return {
        value: data.twr1yAbs ?? 0,
        percentage: data.twr1yRel ?? 0,
      };
  }
}

export interface Point {
  id?: string;
  value: number;
  calendarDate: string;
}

export function filterValuesByTimeframe(timeframe: timeframeId, values?: Point[]): Point[] {
  if (!values) return [];

  let index = 0;

  let conditionDate;

  if (timeframe === 'twrYtd') {
    conditionDate = new Date(new Date().getFullYear(), 0, 1);
  }

  if (timeframe === 'twr1y') {
    conditionDate = new Date();
    conditionDate.setFullYear(conditionDate.getFullYear() - 1);
  }

  if (!conditionDate) return values;

  for (let i = 0; i < values.length; i++) {
    const element = values[i];
    const date = new Date(element.calendarDate);

    if (date.getTime() >= conditionDate.getTime()) {
      index = i;
      break;
    }
  }

  return values.slice(index);
}
