import './slider.scss';
import classnames from 'classnames';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { Stack } from '../stack/stack';
import { Icon } from '../icon/icon';

interface Props {
  controls?: boolean;
  dots?: boolean;
  current?: number;
  children?: ReactNode[];
  onChange?: (active: number) => void;
}

function getObserverMargin(container?: HTMLUListElement): string | undefined {
  if (!container) return;

  const firstElement = container.firstChild as HTMLElement | undefined;
  const secondElement = firstElement?.nextSibling as HTMLElement | undefined;

  if (!firstElement) return;

  let gap = 0;

  if (firstElement && secondElement) {
    gap += firstElement.offsetLeft - secondElement.offsetLeft + secondElement.clientWidth;
  }

  const margin = container.clientWidth - firstElement.clientWidth - Math.abs(gap) * 2;

  return `0px ${-margin / 2}px 0px ${-margin / 2}px`;
}

export const Slider = ({ children, current = 0, controls, dots, onChange }: Props): JSX.Element | null => {
  const ticking = useRef(false);
  const changeOnScroll = useRef(false);
  const sliderRef = useRef<HTMLDivElement>(null);
  const sliderListRef = useRef<HTMLUListElement>(null);
  const activeItemRef = useRef<HTMLLIElement>(null);
  const localCurrent = useRef(current);
  const activeIndex = useRef(0);
  const [itemsLength, setItemsLength] = useState(0);
  const [smooth, setSmooth] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);

  function handleOnChange(index: number, disableHandleScroll?: boolean): void {
    if (disableHandleScroll) {
      changeOnScroll.current = false;
    }

    localCurrent.current = index;
    onChange?.(index);
  }

  // HANDLE SCROLL
  function handleScroll(): void {
    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        if (activeIndex.current === localCurrent.current) {
          changeOnScroll.current = true;
        }

        if (changeOnScroll.current && localCurrent.current !== activeIndex.current) {
          handleOnChange(activeIndex.current);
        }

        ticking.current = false;
      });

      ticking.current = true;
    }
  }

  // HANDLE DRAG SCROLL
  const isDown = useRef(false);
  const startX = useRef(0);
  const scrollLeft = useRef(0);

  function onMouseDown(e: MouseEvent): void {
    isDown.current = true;
    startX.current = e.pageX - (sliderListRef.current?.offsetLeft || 0);
    scrollLeft.current = sliderListRef.current?.scrollLeft || 0;
  }

  function onMouseUp(): void {
    isDown.current = false;
  }

  function handleDragScroll(e: MouseEvent): void {
    if (!isDown.current) return;
    e.preventDefault();
    const x = e.pageX - (sliderListRef.current?.offsetLeft || 0);
    const walk = (x - startX.current) * 2;

    if (sliderListRef.current?.scrollLeft) {
      sliderListRef.current.scrollLeft = scrollLeft.current - walk;
    }
  }

  function centerActiveItem(current: number): void {
    if (!activeItemRef.current || !sliderListRef.current) return;

    if (activeIndex.current === current) {
      changeOnScroll.current = true;
      return;
    }

    const rect = activeItemRef.current.getBoundingClientRect();
    const containerRect = sliderListRef.current.getBoundingClientRect();

    if (rect && containerRect) {
      const offsetLeftContainer = sliderListRef.current.offsetLeft || 0;
      const offsetLeft = activeItemRef.current.offsetLeft || 0;

      const left = offsetLeft - offsetLeftContainer + rect.width / 2 - containerRect.width / 2;

      sliderListRef.current.scrollTo({ left });
    }
  }

  function setObserver(): void {
    const container = sliderListRef.current;
    if (!container || !container.firstChild) return;

    const options = {
      root: container,
      rootMargin: getObserverMargin(container),
      threshold: 1.0,
    };

    observer.current = new IntersectionObserver((entries) => {
      const intersecting = entries.filter((entry) => entry.isIntersecting);

      const visibleNodes = intersecting.map((entry) => {
        return (entry.target as HTMLElement).dataset.index;
      });

      if (visibleNodes.length === 1) {
        activeIndex.current = Number(visibleNodes[0]);
      }
    }, options);

    sliderListRef.current.childNodes.forEach((item) => {
      observer.current?.observe(item as HTMLElement);
    });
  }

  function handleResize(): void {
    setObserver();
  }

  useEffect(() => {
    if (sliderListRef.current) {
      sliderListRef.current.addEventListener('scroll', handleScroll);
      sliderListRef.current.addEventListener('mousemove', handleDragScroll);
      sliderListRef.current.addEventListener('mousedown', onMouseDown);
      sliderListRef.current.addEventListener('mouseup', onMouseUp);
      sliderListRef.current.addEventListener('mouseleave', onMouseUp);
      window.addEventListener('resize', handleResize);
      setItemsLength(sliderListRef.current.childNodes.length);
    }
    setObserver();

    return () => {
      sliderListRef.current?.removeEventListener('scroll', handleScroll);
      sliderListRef.current?.removeEventListener('mousemove', handleDragScroll);
      sliderListRef.current?.removeEventListener('mousedown', onMouseDown);
      sliderListRef.current?.removeEventListener('mouseup', onMouseUp);
      sliderListRef.current?.removeEventListener('mouseleave', onMouseUp);
      window.removeEventListener('resize', handleResize);
      observer.current = null;
    };
  }, []);

  useEffect(() => {
    if (!smooth) {
      setSmooth(true);
    }
    centerActiveItem(current);
  }, [current]);

  if (!children?.length) return null;

  return (
    <div className="slider" ref={sliderRef}>
      <Stack size="s" tabletSize="l">
        <ul className={classnames('slider__list', { 'slider__list--smooth': smooth })} ref={sliderListRef}>
          {children?.map((item, index) => {
            return (
              <li
                className={classnames('slider__item', {
                  'slider__item--active': index === current,
                })}
                data-index={index}
                key={index}
                ref={index === current ? activeItemRef : undefined}
                onClick={() => handleOnChange(index, true)}
              >
                {item}
              </li>
            );
          })}
        </ul>
        {dots && (
          <ul className="slider__dots">
            {children?.map((_, index) => {
              return (
                <li
                  key={index}
                  className={classnames('slider__dot', {
                    'slider__dot--active': index === current,
                  })}
                  onClick={() => {
                    handleOnChange(index, true);
                  }}
                />
              );
            })}
          </ul>
        )}
        {controls && (
          <nav className="slider__nav">
            {current !== 0 && (
              <div className="slider__nav-left" onClick={() => handleOnChange(current - 1, true)}>
                <Icon name="circle-arrow-left" size="xl" />
              </div>
            )}
            {current + 1 !== itemsLength && (
              <div className="slider__nav-right" onClick={() => handleOnChange(current + 1, true)}>
                <Icon name="circle-arrow-right" size="xl" />
              </div>
            )}
          </nav>
        )}
      </Stack>
    </div>
  );
};
