import './textfield.scss';
import classnames from 'classnames';
import React, { useRef } from 'react';
import { Field } from '@nerdgeschoss/react-use-form-library/dist/field';
import { Text } from '../text/text';
import { Stack } from '../stack/stack';
import { formatError } from '../../shared/i18n';

interface Props extends Partial<Field<string | undefined>> {
  label?: string;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  description?: string;
  type?: string;
  adornment?: React.ReactNode;
  autogrow?: boolean;
  formatter?: (val?: string) => string | undefined;
  ariaLabel?: string;
}

export function TextField({
  value,
  label,
  required,
  disabled,
  errors,
  valid,
  touched,
  readOnly,
  placeholder,
  description,
  type,
  adornment,
  formatter,
  ariaLabel,
  onChange,
  onBlur,
  onFocus,
}: Props): JSX.Element {
  const invalid = touched && !valid;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={classnames('textfield', {
        'textfield--filled': value && value.length,
        'textfield--read-only': readOnly,
        disabled,
        invalid,
      })}
    >
      {label !== undefined && (
        <label className={classnames('textfield__label', { 'textfield__label--disabled': disabled })}>{label}</label>
      )}
      <div className="textfield__wrapper">
        {adornment && (
          <div className="textfield__display">
            <Stack line align="top" size="xxxs">
              <div className="textfield__value">{value || placeholder}</div>
              {adornment}
            </Stack>
          </div>
        )}
        <input
          ref={inputRef}
          className="textfield__input"
          value={value !== undefined ? (formatter ? formatter(value) : value) : ''}
          type={type}
          onChange={(event) => {
            if (readOnly) {
              event.preventDefault();
              return;
            }
            onChange?.(event.target.value || undefined);
          }}
          onFocus={() => {
            onFocus?.();
          }}
          onBlur={() => {
            onBlur?.();
          }}
          placeholder={placeholder}
          required={required}
          disabled={disabled || readOnly}
          aria-label={ariaLabel}
        />
      </div>
      {invalid && (
        <ul className="textfield__errors">
          {errors?.map((error) => {
            return (
              <li className="textfield__error" key={error}>
                <Text type="caption">{formatError(error)}</Text>
              </li>
            );
          })}
        </ul>
      )}
      {description && <div className="textfield__description">{description}</div>}
    </div>
  );
}
